import { logError } from 'services/Logger';
import { LanguageTags } from 'i18n';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler } from 'react-hook-form';
import AuthContext from '../../../../contexts/auth/context';
import { IFormPreferences } from './types';
import { saveUserPreferences } from '../../../../services/saveUserPreferences';
import PreferencesContext from '../../../../contexts/preferences/context';

const usePreferences = (handleClose?: () => void) => {
  const { i18n } = useTranslation();
  const { getUserInfo, updateUserflow } = useContext(AuthContext);
  const { selectedLanguage, globalFontSize, updateGlobalFontSize, setLoading } = useContext(PreferencesContext);

  const onSubmit: SubmitHandler<IFormPreferences> = async (form) => {
    setLoading(true);
    try {
      const enableNotifications = form.desktopNotifications;
      const enableSound = form.soundNotifications;
      await saveUserPreferences(
        selectedLanguage || LanguageTags.DEFAULT,
        enableNotifications ? 'all' : 'nothing',
        enableSound ? 100 : 0,
        globalFontSize
      );
      setLoading(false);
      getUserInfo();
      i18n.changeLanguage(selectedLanguage || LanguageTags.DEFAULT);
      updateUserflow(selectedLanguage);
      updateGlobalFontSize(globalFontSize);
      handleClose?.();
    } catch (error) {
      logError('Error while setting user preferences.', error);
      setLoading(false);
    }
  };

  return {
    onSubmit,
  };
};

export default usePreferences;
