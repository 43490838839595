export type Department = {
  _id: string;
  enabled: boolean;
  name: string;
  description: string;
  showOnRegistration: boolean;
  showOnOfflineForm: boolean;
  requestTagBeforeClosingChat: boolean;
  email: string;
  chatClosingTags: string[];
  offlineMessageChannelName: string;
  abandonedRoomsCloseCustomMessage: string;
  waitingQueueMessage: string;
  departmentsAllowedToForward: string;
  _updatedAt: Date;
  numAgents: number;
};

type Phone = {
  phoneNumber: string;
};

type LastChat = {
  _id: string;
  ts: string;
};

type LivechatData = {
  [key: string]: string;
};

type VisitorEmails = {
  address: string;
};

type Contact = {
  _id: string;
  username: string;
  status: `${UserOnlineStatus}`;
  ts: string;
  _updatedAt: string;
  department: string;
  name: string;
  phone: Phone[];
  token: string;
  lastChat: LastChat;
  livechatData: LivechatData;
  visitorEmails: VisitorEmails[];
};

export interface ContactInfo extends Contact {
  lastNotificationInfo?: {
    name: string;
    agent: string;
    date: string;
  };
}

export type Totalizer = {
  title: string;
  value: string;
};

export interface AgentTotalizer {
  success: boolean;
  totalizers: Totalizer[];
}

export interface ChartsTotals {
  closed: number;
  onhold: number;
  open: number;
  queued: number;
  success: boolean;
}

enum CHAT_STATUS {
  open = 'open',
  closed = 'closed',
  onhold = 'onhold',
}

type UserChatStatus = Record<`${CHAT_STATUS}`, string>;

export type ChatStatus = UserChatStatus & { username: string };

export const hasStatus = (content: Record<string, string>): content is UserChatStatus =>
  Object.keys(CHAT_STATUS).some((key) => key in content);

const BUSIEST_TIME = 'Busiest_time';
export const isBusiestTime = (content: Totalizer) => content.title === BUSIEST_TIME;
export type Agent = {
  _id: string;
  agentId: string;
  departmentId: string;
  _updatedAt: string;
  count: number;
  departmentEnabled: boolean;
  order: number;
  username: string;
};

export type Notification = {
  ID: string;
  Template: {
    id: string;
    name: string;
  };
  Contact: {
    id: string;
    name: string;
  };
  Agent: {
    id: string;
    name: string;
  };
  CreatedAt: string;
};

export type Button = {
  Text: string;
  Type: string;
};

type MessageHeader = {
  Media: {
    Type: string;
    Caption: string;
    Link: string;
    ID: string;
  };
};

type Body = {
  Text: string;
};

type Footer = {
  Text: string;
};

export type Message = {
  TemplateID: string;
  Name: string;
  Description: string;
  LanguageCode: string;
  LanguagePolicy: string;
  Namespace: string;
  Header?: MessageHeader;
  Body: Body;
  Footer?: Footer;
  Buttons?: Button[];
  Parameters: string[];
};

type SharedWith = {
  id: string;
  name: string;
};

export type Template = {
  ID: string;
  SubscriptionID: string;
  Name: string;
  BodyText: string;
  Status: string;
  IsActive: boolean;
  IsActiveSalesdesk: boolean;
  SharedWith: SharedWith[];
  UpdatedAt: string;
  CreatedAt: string;
};

export interface TemplateInfo extends Template {
  Messages: Message[];
}

type UserEmail = {
  address: string;
  verified: boolean;
};

export type User = {
  _id: string;
  username: string;
  emails: UserEmail[];
  status: `${UserOnlineStatus}`;
  name: string;
  livechat: {
    maxNumberSimultaneousChat: string;
  };
  statusLivechat: string;
  monitorId?: string;
};

type CustomTag = {
  tag: string;
  customColor: {
    text: string;
    color: string;
  };
};

export type CannedMessage = {
  _id: string;
  shortcut: string;
  text: string;
  scope: string;
  tags: string[];
  customTags?: CustomTag[];
  createdBy: {
    _id: string;
    username: string;
  };
  _createdAt: string;
  _updatedAt: string;
  departmentId?: string;
  userId?: string;
};

export interface IDepartmentsListUnit extends Department {
  maxNumberSimultaneousChat: string;
  visitorInactivityTimeoutInSeconds: string;
  ancestors: string[];
  parentId: string;
  fallbackForwardDepartment: string;
}

export type Visitor = {
  _id: string;
  username: string;
  name: string;
  lastChat: LastChat;
  livechatData?: LivechatData;
  phone?: Phone[];
  visitorEmails?: VisitorEmails[];
};

export type Unit = {
  _id: string;
  name: string;
  visibility: string;
  type: string;
  numMonitors: number;
  numDepartments: number;
  _updatedAt: string;
};

export type Monitors = {
  _id: string;
  monitorId: string;
  unitId: string;
  _updatedAt: string;
  username: string;
};

type EmailUserIInfo = {
  address: string;
  verified: boolean;
};

export enum UserOnlineStatus {
  Online = 'online',
  Offline = 'offline',
  Inactive = 'inactive',
}

export enum UserTypes {
  Admin = 'admin',
  App = 'app',
  Bot = 'bot',
  System = 'system',
  User = 'user',
}

export type UserInfo = {
  _id: string;
  emails?: EmailUserIInfo[];
  type?: `${UserTypes}`;
  status: `${UserOnlineStatus}`;
  active: boolean;
  roles: string[];
  name: string;
  lastLogin?: string;
  username: string;
  avatarETag?: string;
  nameInsensitive?: string;
};

export type Tags = {
  _id: string;
  name: string;
  description: string;
  numDepartments: number;
  departments: string[];
  _updatedAt: string;
  customColor: {
    color: string;
    text: string;
  };
};

export type RocketChatUser = {
  userId?: string;
  email?: string;
  name?: string;
  password?: string;
  username?: string;
  active?: boolean;
  roles?: string[];
  joinDefaultChannels?: boolean;
  requirePasswordChange?: boolean;
  sendWelcomeEmail?: boolean;
  setRandomPassword?: boolean;
  verified?: boolean;
  customFields?: Object;
};

export const AuthorRoles = {
  User: 'user',
  Bot: 'bot',
} as const;

export type ValueOf<T> = T[keyof T];

export const UserStatusFilterState = {
  All: null,
  Active: true,
  Inactive: false,
} as const;

export type UserStatusFilterStateValues = ValueOf<typeof UserStatusFilterState>;
