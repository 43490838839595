import { useState, useEffect, useCallback } from 'react';
import { format, startOfDay, endOfDay } from 'date-fns';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import FeatureFlags from 'constants/featureFlags';
import useEnvironment from '../../../hooks/useEnvironments';
import { Department, ChatStatus } from '../../../services/types';
import { STORAGE_KEYS } from '../../../constants/defaultValues';
import { renderAvatar } from './RenderAvatar';
import { getUserStats } from './getUserStats';
import { loadTeams } from './loadTeams';
import { loadAgentslist } from './loadAgentslist';
import { IConversationStatus, getConversationsStats } from './getConversationsStats';
import { getDateFormat } from './getDateFormat ';

interface IAgents {
  agent?: unknown;
  username: string;
  status?: string;
  open?: string;
  closed?: string;
  onhold?: string;
  avatarETag?: string;
  name?: string;
  type?: string;
  roles?: string[];
}

export interface IDepartments extends Department {
  unitId?: string;
  unitName?: string;
  numMonitors?: number;
}

export interface ITeamsDashboardAnalytics {
  _id: string;
  name: string;
}

interface IAgentTableAnalytics {
  agent: React.ReactNode;
  username: string;
  status?: string;
  open?: string;
  closed?: string;
}

const TWENTY_FOUR_HOURS_IN_MILISECONDS = 86400000;
const THIRTY_SECONDS_IN_MILISECONDS = 30000;
const ONE_MINUTE_IN_MILISECONDS = 60000;
const FIVE_MINUTES_IN_MILISECONDS = 300000;
const TEN_MINUTES__IN_MILISECONDS = 600000;

export const TimeUpdateOptions = [
  { prefix: '', title: 'timeSelectorNever', value: TWENTY_FOUR_HOURS_IN_MILISECONDS },
  { prefix: 'every', title: '30 s', value: THIRTY_SECONDS_IN_MILISECONDS },
  { prefix: 'every', title: '1 min', value: ONE_MINUTE_IN_MILISECONDS },
  { prefix: 'every', title: '5 min', value: FIVE_MINUTES_IN_MILISECONDS },
  { prefix: 'every', title: '10 min', value: TEN_MINUTES__IN_MILISECONDS },
];

export const NOT_USERS = ['system', 'bot', 'app', 'admin'];

export default function useServiceDashboard() {
  const [timeUpdateSelected, setTimeUpdateSelected] = useState(TWENTY_FOUR_HOURS_IN_MILISECONDS);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);
  const [teams, setTeams] = useState<ITeamsDashboardAnalytics[]>([]);
  const [agents, setAgents] = useState<IAgents[]>([]);
  const [conversationStats, setConversationStats] = useState<IConversationStatus>({} as IConversationStatus);
  const [agentsStats, setAgentStats] = useState<ChatStatus[]>([]);
  const [agentsTable, setAgentsTable] = useState<IAgents[]>([]);
  const [tableHeader, setTableHeader] = useState<string[]>([]);
  const [totalOnline, setTotalOnline] = useState(0);
  const showOnHold = useFeatureFlag(FeatureFlags.SHOW_ON_HOLD_METRIC);
  const [selectedTeam, setSelectedTeam] = useState(() => {
    const previousSelectedTeam = localStorage.getItem(STORAGE_KEYS.DASHBOARD_SELECTED_TEAM);
    if (previousSelectedTeam) {
      return previousSelectedTeam;
    }
    return '';
  });
  const dateStart = startOfDay(new Date()).toISOString();

  const [dateEnd, setDateEnd] = useState(endOfDay(new Date()).toISOString());
  const [formatedDate, setFormatedDate] = useState('');
  const [formatedHour, setFormatedHour] = useState(format(new Date().getTime(), 'HH:mm'));
  const [loadingDataError, setLoadingDataError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');

  const loadDashboard = useCallback(
    async (teamId: string) => {
      const resultConversationStatus = await getConversationsStats(dateStart, dateEnd, teamId, showOnHold);
      setConversationStats(resultConversationStatus);
      setAgentStats(await getUserStats(dateStart, dateEnd, teamId));
      setAgents(await loadAgentslist(selectedTeam));
      setLoading(false);
    },
    [dateEnd, dateStart, selectedTeam, showOnHold]
  );

  useEffect(() => {
    const dateFormat = getDateFormat();
    const fetchBaseData = async () => {
      const { finalTeams, previousSelectedTeam } = await loadTeams();
      setTeams(finalTeams);
      setSelectedTeam(previousSelectedTeam);
    };

    fetchBaseData().catch(() => setLoadingDataError(true));
    setFormatedDate(format(new Date(), dateFormat));
  }, []);

  useEffect(() => {
    const agentColumn = (item: IAgents) => {
      const { username, name, avatarETag } = item;
      return renderAvatar(baseUrl, username, avatarETag, name);
    };
    let finalAgents: IAgents[] = [];

    if (agentsStats) {
      finalAgents = agents.reduce((accumulator, currentAgent) => {
        const { username, status } = currentAgent;
        const agent = agentsStats.find((item) => item.username === username);

        const newAgent: IAgentTableAnalytics = {
          agent: agentColumn(currentAgent),
          username,
          status,
          open: '',
          closed: '',
        } as IAgentTableAnalytics;

        if (agent) {
          newAgent.open = agent.open;
          newAgent.closed = agent.closed;
        }

        accumulator.push(newAgent);

        return accumulator;
      }, finalAgents);
    }

    setAgentsTable(finalAgents);

    const currentTotalOnline = finalAgents.filter((item) => item.status === 'online').length;

    setTotalOnline(currentTotalOnline);

    if (finalAgents.length) {
      setTableHeader(Object.keys(finalAgents[0]));
    }
  }, [agentsStats, agents]);

  useEffect(() => {
    setDateEnd(endOfDay(new Date()).toISOString());
    if (selectedTeam) {
      setLoading(true);
      loadDashboard(selectedTeam);
    }
    localStorage.setItem(STORAGE_KEYS.DASHBOARD_SELECTED_TEAM, selectedTeam);
  }, [selectedTeam]);

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const id = setInterval(() => {
      setDateEnd(endOfDay(new Date()).toISOString());
      setFormatedHour(format(new Date().getTime(), 'HH:mm'));
      setLoading(true);
      loadDashboard(selectedTeam);
    }, timeUpdateSelected);
    setIntervalId(id);
    return () => clearInterval(intervalId);
  }, [timeUpdateSelected]);

  return {
    timeUpdateSelected,
    setTimeUpdateSelected,
    selectedTeam,
    setSelectedTeam,
    teams,
    agents,
    conversationStats,
    agentsTable,
    tableHeader,
    totalOnline,
    formatedDate,
    formatedHour,
    loadingDataError,
    setLoadingDataError,
    loading,
  };
}
