import { differenceInHours, differenceInDays, differenceInMinutes, format, sub, add } from 'date-fns';
import { enUS, ptBR, es } from 'date-fns/locale';
import { LanguageTags, isLanguageTag } from 'i18n';

const today = new Date();

export const convertDatesTable = (
  dateParam: string,
  location: string,
  tableTemplate: 'templates' | 'templatesHistory'
) => {
  const dataString = format(new Date(dateParam.slice(0, 19)), location === 'pt-br' ? 'dd/MM/yyyy' : 'MM/dd/yyyy');

  const hourString = format(new Date(dateParam.slice(0, 19)), location === 'pt-br' ? 'HH:mm' : 'hh:mm a');

  const timezone = format(new Date(dateParam.slice(0, 19)), 'XXXXX');
  const timeToAddOrSub = `${timezone[1]}${timezone[2]} .${timezone[4]}${timezone[5]}`;

  let dateParamConverted;
  if (timezone[0] === '-') {
    dateParamConverted = sub(new Date(dateParam.slice(0, 19)), {
      hours: parseInt(timeToAddOrSub, 10),
    });
  } else {
    dateParamConverted = add(new Date(dateParam.slice(0, 19)), {
      hours: parseInt(timeToAddOrSub, 10),
    });
  }

  const daysDiff = differenceInDays(today, dateParamConverted);
  const hoursDiff = differenceInHours(today, dateParamConverted);
  const minutesDiff = differenceInMinutes(today, dateParamConverted);

  if (tableTemplate === 'templates') {
    if (daysDiff === 0) {
      return { date: '', time: '', text: 'today' };
    }
    if (daysDiff === 1) {
      return { date: '', time: '', text: 'yesterday' };
    }
    if (daysDiff >= 2 && daysDiff <= 7) {
      return { date: '', time: daysDiff, text: 'daysAgo' };
    }

    return { date: dataString, time: '', text: '' };
  }

  if (daysDiff === 0 && hoursDiff === 0 && minutesDiff <= 5) {
    return { date: '', time: '', text: 'justNow' };
  }

  if (daysDiff === 0 && hoursDiff === 0 && minutesDiff >= 6 && minutesDiff <= 59) {
    return { date: '', time: minutesDiff, text: 'minutesAgo' };
  }

  if (daysDiff === 0 && hoursDiff >= 1 && hoursDiff < 2 && minutesDiff >= 60) {
    return { date: '', time: '', text: 'anHourAgo' };
  }

  if (daysDiff === 0 && hoursDiff >= 2 && hoursDiff <= 23 && minutesDiff >= 60) {
    return { date: 'today', time: hoursDiff, text: 'hoursAgo' };
  }

  if (daysDiff === 1 && hoursDiff > 23) {
    return { date: 'yesterday', time: hourString, text: '' };
  }

  if (daysDiff > 1 && daysDiff <= 7) {
    return { date: '', time: hourString, text: `${daysDiff} daysAgo` };
  }

  return { date: dataString, time: hourString, text: '' };
};

export const convertToLocaleDateString = (date: string | number, language: string) =>
  new Date(date).toLocaleDateString(language, {
    hour: '2-digit',
    minute: '2-digit',
  });

export const convertTime12to24 = (time12h: string, language: string) => {
  const [initialBusiestTime, finalBusiestTime] = time12h.split('-');

  if (!language.includes('pt')) {
    return `${initialBusiestTime.trim()}-${finalBusiestTime.trim()}`;
  }

  const initialModifier = initialBusiestTime.trim().substring(2, 4);
  const finalModifier = finalBusiestTime.trim().substring(2, 4);

  const initialHours = initialBusiestTime.trim().substring(0, 2);
  const endHours = finalBusiestTime.trim().substring(0, 2);

  let finalIniHours = parseInt(initialHours, 10);
  let finalEndHours = parseInt(endHours, 10);

  let auxFinalIniHours = initialHours;
  let auxFinalEndHours = endHours;

  if (initialHours === '12') {
    auxFinalIniHours = '00';
  }
  if (endHours === '12') {
    auxFinalEndHours = '00';
  }
  if (initialModifier === 'PM') {
    finalIniHours = parseInt(auxFinalIniHours, 10) + 12;
  }

  if (finalModifier === 'PM') {
    finalEndHours = parseInt(auxFinalEndHours, 10) + 12;
  }

  return `${finalIniHours}H - ${finalEndHours}H`;
};

const localeFormats: Record<LanguageTags, string> = {
  [LanguageTags.EN]: 'MMM dd yyyy',
  [LanguageTags.PT_BR]: 'dd MMM yyyy',
  [LanguageTags.ES]: 'dd MMM yyyy',
};

export const dateFnsLocales: Record<LanguageTags, Locale> = {
  [LanguageTags.EN]: enUS,
  [LanguageTags.PT_BR]: ptBR,
  [LanguageTags.ES]: es,
};

export const getDateFnsLocale = (localeString: string) => {
  const locale = isLanguageTag(localeString) ? localeString : LanguageTags.DEFAULT;
  return dateFnsLocales[locale];
};

export const formatDate = (date: Date, localeString: string): string => {
  const locale = isLanguageTag(localeString) ? localeString : LanguageTags.DEFAULT;

  return format(date, localeFormats[locale], {
    locale: dateFnsLocales[locale],
  });
};

export const IVALID_DATE_ERROR_MESSAGE = 'Invalid date or timestamp';

export const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

export const formatMessageTime = (dateValue: string | number, language?: string) => {
  const date = new Date(dateValue);

  if (!isValidDate(date)) {
    throw new Error(IVALID_DATE_ERROR_MESSAGE);
  }

  return date.toLocaleTimeString([language || 'en'], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const convertDateToSeparator = (dateValue: string | number | Date): string => {
  const date = new Date(dateValue);

  if (!isValidDate(date)) {
    throw new Error(IVALID_DATE_ERROR_MESSAGE);
  }

  return format(date, 'yyyy-MM-dd');
};

export const convertSeparatorToDate = (separator: string): Date => {
  const [year, month, day] = separator.split('-').map(Number);
  const date = new Date(year, month - 1, day);

  return date;
};
