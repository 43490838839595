import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBrI18n from './pt_BR.i18n.json';
import enI18n from './en.i18n.json';
import esI18n from './es_419.i18n.json';
import { STORAGE_KEYS } from '../constants/defaultValues';

export enum LanguageTags {
  PT_BR = 'pt-BR',
  EN = 'en',
  ES = 'es',
  DEFAULT = 'pt-BR',
}

const resources: Record<LanguageTags, Resource> = {
  [LanguageTags.EN]: { translation: enI18n },
  [LanguageTags.PT_BR]: { translation: ptBrI18n },
  [LanguageTags.ES]: { translation: esI18n },
};

export const isLanguageTag = (key: unknown): key is LanguageTags =>
  typeof key === 'string' ? key in resources : false;

export const getNormalizedLanguageTag = (languageTag: string): LanguageTags => {
  const matchingTag = Object.values(LanguageTags).find((value) =>
    languageTag.toLowerCase().startsWith(value.toLowerCase())
  );

  return matchingTag || LanguageTags.DEFAULT;
};

export const evaluateLanguage = (): string => {
  const storedLanguage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);

  if (isLanguageTag(storedLanguage)) {
    return storedLanguage;
  }

  return getNormalizedLanguageTag(window.navigator.language);
};

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: LanguageTags.DEFAULT,
  interpolation: {
    escapeValue: false,
  },
  lng: evaluateLanguage(),
  resources,
});

export default i18n;
