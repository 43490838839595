export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum EXPECTED_HTTP_RESPONSE {
  NoContent = 204,
}

export enum HourRange {
  START = 'opens',
  END = 'closes',
}

export interface IWorkHour {
  dayOfWeek: DayOfWeek;
  [HourRange.START]: string;
  [HourRange.END]: string;
}

export interface ISchedulePost {
  name: string;
  departmentIds: string[];
  timezone: string;
  workHours: IWorkHour[];
}

export interface ISchedule extends ISchedulePost {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  enabledAt?: string;
  disabledAt?: string;
  deletedAt?: string;
  updatedBy?: string;
}
