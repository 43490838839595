import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { LanguageTags } from 'i18n';
import userflow from 'userflow.js';
import isEqual from 'lodash.isequal';
import { User } from 'services/types';
import { initUserStatusChecker } from 'utils/userStatusChecker';
import updateHtmlFontSize from 'utils/updateHtmlFontSize';
import { getConfigAllowAgentsPlaceOnHoldManual } from '../../services/getConfigAllowAgentsPlaceOnHoldManual';
import { getConfigForwardChatOmnichannel } from '../../services/getConfigForwardChatOmnichannel';
import { getConfigRoutingMethod } from '../../services/getConfigRoutingMethod';
import { getDepartmentsOfAgent } from '../../services/getDepartmentsOfAgent';
import { getLivechatUserInfo } from '../../services/getLivechatUserInfo';
import { me } from '../../services/me';
import { RoutingMethods, UserInformation, UserType } from '../../types/user';
import AuthContext from './context';
import { BROWSER_NOTIFICATION, STORAGE_KEYS, userflowLanguageCodeMap } from '../../constants/defaultValues';
import { ChangeAgentStatusArgs } from './types';

const AuthProvider: React.FC = (props) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const [currentUser, setCurrentUser] = useState<UserType>({} as UserType);
  const [currentUserInfo, setCurrentUserInfo] = useState<UserInformation>({} as UserInformation);
  const [forwardChat, setForwardChat] = useState('');
  const [routingMethod, setRoutingMethod] = useState<RoutingMethods | ''>('');
  const [networkErrorFound, setNetworkErrorFound] = useState(false);
  const [allowsManualOnHold, setAllowsAgentsManualOnHold] = useState(null);
  const navigate = useNavigate();

  const currentUserId = useMemo(() => currentUserInfo._id, [currentUserInfo._id]);
  const localStorageUserId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const { fontSize } = currentUserInfo?.settings?.preferences || {};

  const updateCurrentUserInfo = useCallback((data: Partial<UserInformation>) => {
    setCurrentUserInfo((oldState) => {
      const newState = { ...oldState, ...data };
      if (isEqual(oldState, newState)) {
        return oldState;
      }
      return newState;
    });
  }, []);

  const getUserInfo = useCallback(async () => {
    const id = currentUser?.userId || currentUserId || localStorage.getItem(STORAGE_KEYS.USER_ID);

    try {
      if (id) {
        const { data: user } = await me();
        const {
          data: { departments = [] },
        } = await getDepartmentsOfAgent(id);
        const { settings } = user;
        const { preferences } = settings;
        updateCurrentUserInfo({
          departments,
          ...user,
        });
        localStorage.setItem(STORAGE_KEYS.ROLES, JSON.stringify(user.roles));
        localStorage.setItem(STORAGE_KEYS.LANGUAGE, user.language || window.navigator.language);
        localStorage.setItem(STORAGE_KEYS.TIMEOUT, preferences.idleTimeLimit);

        localStorage.setItem(BROWSER_NOTIFICATION.SOUND_VOLUME, user.settings.preferences.notificationsSoundVolume);
        localStorage.setItem(
          BROWSER_NOTIFICATION.NEW_ROOM_NOTIFICATION_SOUND,
          user.settings.preferences.newRoomNotification
        );
        localStorage.setItem(
          BROWSER_NOTIFICATION.NEW_MESSAGE_NOTIFICATION_SOUND,
          user.settings.preferences.newMessageNotification
        );
      } else {
        localStorage.clear();
        navigate('/login');
      }
    } catch (e) {
      console.log('error', e);
    }
  }, [currentUser?.userId, currentUserId, updateCurrentUserInfo, navigate]);

  const handleCurrentUser = useCallback(
    (data: UserType) => {
      const { authToken } = data;
      const { userId } = data;
      const roles = data.me?.roles || [];
      const language = data.me?.language || LanguageTags.DEFAULT;
      const username = data.me?.username || '';
      localStorage.setItem(STORAGE_KEYS.USERNAME, username);
      localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, authToken);
      localStorage.setItem(STORAGE_KEYS.USER_ID, userId);
      localStorage.setItem(STORAGE_KEYS.ROLES, JSON.stringify(roles));
      localStorage.setItem(STORAGE_KEYS.LANGUAGE, language);
      i18n.changeLanguage(language);
      setCurrentUser(data);
    },
    [i18n]
  );

  const handleCurrentUserInformation = useCallback(
    (data: UserInformation) => {
      updateCurrentUserInfo(data);
      getUserInfo();
    },
    [updateCurrentUserInfo, getUserInfo]
  );

  const changeAgentStatus = useCallback(
    ({ status = currentUserInfo.status, statusLivechat = currentUserInfo.statusLivechat }: ChangeAgentStatusArgs) => {
      updateCurrentUserInfo({
        status,
        statusLivechat,
      });
    },
    [updateCurrentUserInfo, currentUserInfo.status, currentUserInfo.statusLivechat]
  );

  const getLivechatInfo = useCallback(async () => {
    const id = localStorage.getItem(STORAGE_KEYS.USER_ID) || currentUserId;
    if (id) {
      try {
        const { data } = await getLivechatUserInfo(id);
        setNetworkErrorFound(false);
        updateCurrentUserInfo({
          status: data.user.status,
          statusLivechat: data.user.statusLivechat,
          livechat: data.user.livechat,
        });
      } catch (error) {
        setNetworkErrorFound(true);
      }
    }
  }, [currentUserId, updateCurrentUserInfo]);

  const setUserLivechat = useCallback(
    (user: User) => {
      updateCurrentUserInfo({
        status: user.status,
        statusLivechat: user.statusLivechat,
        livechat: user.livechat,
      });
    },
    [updateCurrentUserInfo]
  );

  const loadCurrentUser = useCallback(() => {
    const authToken = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN)?.toString();
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID)?.toString();
    if (authToken && userId) {
      setCurrentUser({ authToken, userId });
    }
  }, []);

  const getUserRoles = useCallback(() => {
    const data = localStorage.getItem(STORAGE_KEYS.ROLES);
    if (data) {
      return JSON.parse(data);
    }
    return [];
  }, []);

  const isAuthenticated = useCallback(() => {
    const authToken = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN)?.toString();
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID)?.toString();
    if (authToken && userId) {
      return true;
    }
    return false;
  }, []);

  const getCurrentUserId = useCallback(() => {
    const userId = localStorage.getItem(STORAGE_KEYS.USER_ID)?.toString();
    return currentUser.userId || userId;
  }, [currentUser.userId]);

  const getOmnichannelConfig = useCallback(async () => {
    const { data } = await getConfigForwardChatOmnichannel();

    setForwardChat(data.value);
  }, [setForwardChat]);

  const getRoutingMethod = useCallback(async () => {
    const { data } = await getConfigRoutingMethod();

    setRoutingMethod(data.value);
  }, [setRoutingMethod]);

  const getAllowAgentsPlaceOnHoldManual = useCallback(async () => {
    const { data } = await getConfigAllowAgentsPlaceOnHoldManual();

    setAllowsAgentsManualOnHold(data.value);
  }, [setAllowsAgentsManualOnHold]);

  const updateAvatarURL = useCallback(
    (url: string) => {
      updateCurrentUserInfo({
        avatarUrl: url,
      });
    },
    [updateCurrentUserInfo]
  );

  const updateDataDogUser = useCallback(() => {
    const { _id: id, name, email } = currentUserInfo;
    datadogRum.setUser({
      id,
      name,
      email,
    });
  }, [currentUserInfo]);

  const updateUserflow = useCallback(
    (selectedLanguage?: string) => {
      if (currentUserInfo) {
        const { _id: id, name, email, _updatedAt: updatedAt, language: userInfoLanguage } = currentUserInfo;
        const language = selectedLanguage ?? userInfoLanguage;

        userflow.identify(id, {
          name,
          email,
          signed_up_at: updatedAt,
          locale_code: userflowLanguageCodeMap[language] ?? LanguageTags.DEFAULT,
        });
      }
    },
    [currentUserInfo]
  );

  useEffect(() => {
    let cleaner: () => void;
    const cleanChecker = async () => {
      const checker = await initUserStatusChecker({ setter: setUserLivechat });
      return checker;
    };
    cleanChecker().then((cleanup) => {
      cleaner = cleanup;
    });

    return () => {
      cleaner?.();
    };
  }, [localStorageUserId, setUserLivechat]);

  useEffect(() => {
    if (!currentUserId) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      getLivechatInfo();
      loadCurrentUser();
      getOmnichannelConfig();
      getAllowAgentsPlaceOnHoldManual();
      getRoutingMethod();
      updateUserflow();
      updateDataDogUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  useEffect(() => {
    if (fontSize) {
      updateHtmlFontSize(fontSize);
      localStorage.setItem(STORAGE_KEYS.FONTSIZE, fontSize);
    }
  }, [fontSize]);

  const memoizedValues = useMemo(
    () => ({
      currentUser,
      handleCurrentUser,
      isAuthenticated,
      currentUserInfo,
      handleCurrentUserInformation,
      getCurrentUserId,
      changeAgentStatus,
      getUserRoles,
      getLivechatInfo,
      allowsManualOnHold,
      getUserInfo,
      forwardChat,
      updateAvatarURL,
      routingMethod,
      networkErrorFound,
      setNetworkErrorFound,
      updateUserflow,
      updateDataDogUser,
      updateCurrentUserInfo,
    }),
    [
      currentUser,
      handleCurrentUser,
      isAuthenticated,
      currentUserInfo,
      handleCurrentUserInformation,
      getCurrentUserId,
      changeAgentStatus,
      getUserRoles,
      getLivechatInfo,
      allowsManualOnHold,
      getUserInfo,
      forwardChat,
      updateAvatarURL,
      routingMethod,
      networkErrorFound,
      setNetworkErrorFound,
      updateUserflow,
      updateDataDogUser,
      updateCurrentUserInfo,
    ]
  );

  return <AuthContext.Provider value={memoizedValues}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
