import { logError } from 'services/Logger';

export const USAGE_ORDER_KEY = 'usageOrder';

export const loadUsageOrder = (): string[] => {
  try {
    const data = localStorage.getItem(USAGE_ORDER_KEY);
    return data ? JSON.parse(data) : [];
  } catch (error) {
    logError('Error loading usage order from local storage:', error);
    return [];
  }
};

let usageOrder = loadUsageOrder();

export const saveUsageOrder = (order: string[]) => {
  try {
    localStorage.setItem(USAGE_ORDER_KEY, JSON.stringify(order));
  } catch (error) {
    logError('Error saving usage order to local storage:', error);
  }
};

export const updateUsageOrder = (key: string) => {
  usageOrder = usageOrder.filter((item) => item !== key);
  usageOrder.push(key);
  saveUsageOrder(usageOrder);
};

const handleQuotaExceededError = <T>(key: string, value: T) => {
  while (usageOrder.length > 0) {
    const oldestKey = usageOrder.shift();
    if (oldestKey) {
      localStorage.removeItem(oldestKey);
    }
    try {
      localStorage.setItem(key, JSON.stringify(value));
      updateUsageOrder(key);
      return;
    } catch (error) {
      if ((error as DOMException).name !== 'QuotaExceededError') {
        logError('Error setting local storage item:', error);
      }
    }
  }
  if (usageOrder.length === 0) {
    localStorage.clear();
    try {
      localStorage.setItem(key, JSON.stringify(value));
      updateUsageOrder(key);
    } catch (error) {
      logError('Error setting local storage item:', error);
    }
  }
};

export const setItem = <T>(key: string, value: T): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    updateUsageOrder(key);
  } catch (error) {
    if ((error as DOMException).name === 'QuotaExceededError') {
      handleQuotaExceededError(key, value);
    } else {
      logError('Error setting local storage item:', error);
    }
  }
};

export const getItem = <T>(key: string): T | null => {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      updateUsageOrder(key);
      return JSON.parse(data) as T;
    }
    return null;
  } catch (error) {
    logError('Error getting local storage item:', error);
    return null;
  }
};
