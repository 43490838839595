import { getDashChartsPerAgent } from '../../../services/dashChartsPerAgent';
import { hasStatus, ChatStatus } from '../../../services/types';

export const getUserStats = async (dateStart: string, dateEnd: string, teamId: string) => {
  const { data } = await getDashChartsPerAgent(dateStart, dateEnd, teamId);

  delete data.success;

  const validUsers = Object.keys(data).reduce((memo, key) => {
    const chatStatus = data[key];
    if (hasStatus(chatStatus)) {
      memo.push({
        username: key,
        open: chatStatus.open,
        closed: chatStatus.closed,
        onhold: chatStatus.onhold,
      });
    }
    return memo;
  }, [] as ChatStatus[]);

  return validUsers;
};
