import React, { useContext } from 'react';
import { t } from 'i18next';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { Button, IconButton } from '@engyalo/design-system';
import CustomTable from '../../../components/Table';
import { Container, StackTitle, Header, Main, Pagination } from './style';
import useContactManager, { SortOptions, SortValue, TABLE_COLUMNS, TableRowData } from './useContactManager';
import InputSearch from '../../../components/InputSearch';
import { TableContainer, SearchContainer } from '../../ServiceManagement/style';
import CustomPagination from '../../../components/Pagination';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import MenuChatsContext from '../../../contexts/menuChats/context';
import DrawerContext, { TABS } from '../../../contexts/drawer/context';
import Drawer from '../../../components/Drawer';
import FormNewContact from './FormNewContact';

const isValidSortType = (option: string): option is SortOptions => {
  const validOptions = [...Object.values(TABLE_COLUMNS), ''];

  return validOptions.includes(option);
};

const isValidSortValue = (value: number): value is SortValue => value === -1 || value === 1;

const ContactManager = () => {
  const {
    anchorElKebab,
    contactSearched,
    contactsOrder,
    currentPage,
    headerColumns,
    inputText,
    loading,
    openNewUserDrawer,
    sortContactsBy,
    tableRows,
    totalItems,
    handleCurrentPage,
    handleOpenKebab,
    loadContacts,
    setOpenNewUserDrawer,
    setInputText,
  } = useContactManager();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { loadUserInfo, handleTabSelection } = useContext(DrawerContext);

  const { setOpenContactManager } = useContext(MenuChatsContext);

  const handleOpenManagerDrawer = (user: TableRowData) => {
    const { _id: userId } = user;

    // The user info is loaded directly using `loadUserInfo`.
    // Should this be moved to the context?
    // This flow should be reviewed in futue refactorings.
    loadUserInfo(userId);
    handleTabSelection(TABS.CONTACT_INFO);
  };

  const handleCallApi = (sortType: string, sortValue: number) => {
    if (isValidSortType(sortType) && isValidSortValue(sortValue)) {
      loadContacts(inputText, sortType, sortValue);
    }
  };

  const { innerHeight } = window;

  const handleRenderTableHomeContactManager = () => {
    if (totalItems >= 1) {
      return (
        <>
          <TableContainer>
            <CustomTable
              rows={tableRows}
              hasButtonOptions={false}
              handleClickItem={handleOpenManagerDrawer}
              page={currentPage}
              headItems={headerColumns}
              handleCallApi={handleCallApi}
              order={contactsOrder}
              orderBy={sortContactsBy}
              hasKebabButton
              handleOpenKebab={handleOpenKebab}
              tableName="contactManager"
              anchorElKebab={anchorElKebab}
            />
          </TableContainer>
          <Pagination isMobile={isMobile}>
            <CustomPagination
              isMobile={isMobile}
              count={totalItems}
              page={currentPage}
              handleChangePage={handleCurrentPage}
            />
          </Pagination>
        </>
      );
    }

    if (contactSearched && !totalItems) {
      return (
        <Typography
          data-testid="noContactsFoundHomeContactManager"
          variant="body1"
          color="neutral.var70"
          textAlign="center"
        >
          {t('noContactsFound')}
        </Typography>
      );
    }

    if (!totalItems && !loading) {
      return (
        <Typography
          data-testid="emptyContactsFoundHomeContactManager"
          variant="body1"
          color="neutral.var70"
          textAlign="center"
        >
          {t('emptyContacts')}
        </Typography>
      );
    }

    return (
      <Box alignSelf="center">
        <Loading />
      </Box>
    );
  };

  return (
    <Container data-testid="homeContactManager" sx={{ height: isMobile ? innerHeight : '100%' }}>
      <Header>
        <IconButton
          data-testid="btnCloseContactManagerHome"
          onClick={() => setOpenContactManager(false)}
          aria-label={t('close')}
          variant="text"
          color="neutral2"
        >
          <i className="fa-solid fa-arrow-left fa-2xs" />
        </IconButton>

        <StackTitle variant="subtitle2" color="neutral.var90">
          {t('manageContacts')}
        </StackTitle>
      </Header>
      <Main isMobile={isMobile}>
        {!isMobile && (
          <Typography data-testid="homeContactsHeaderContactManager" variant="h2" color="neutral.var80">
            {t('contacts')}
          </Typography>
        )}
        <SearchContainer isMobile={isMobile}>
          <InputSearch
            testId="inputSearchHomeContactManager"
            padding="8px 16px 8px 0px"
            placeholder={t('searchByNameAndPhone')}
            value={inputText}
            onChange={(value) => {
              loadContacts(value);
              setInputText(value);
            }}
          />
          <Button
            size="small"
            onClick={() => setOpenNewUserDrawer(true)}
            variant="outlined"
            sx={{
              minInlineSize: 'fit-content',
            }}
          >
            {t('newContact')}
          </Button>
        </SearchContainer>
        {handleRenderTableHomeContactManager()}
      </Main>
      {openNewUserDrawer && (
        <Drawer open={openNewUserDrawer} isMobile={isMobile}>
          <FormNewContact handleOpenNewUser={() => setOpenNewUserDrawer(false)} />
        </Drawer>
      )}
    </Container>
  );
};

export default ContactManager;
