import { CannedMessage } from 'services/types';

export type IStatusChat = 'all' | 'open' | 'closed' | 'onhold';
export enum STATUS_UPLOAD {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface FileQueue {
  name: string;
  status?: STATUS_UPLOAD;
  progress?: number;
}

export type IChatStore = {
  selectedCannedMessages: Record<string, CannedMessage | null>;
  roomTagNames: string[];
  fileQueue: FileQueue[];
};
