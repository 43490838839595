import * as React from 'react';
import { Modal, Fade, Typography } from '@mui/material';
import { IconButton } from '@engyalo/design-system';
import { IModal } from './types';
import { ModalHeader, ModalWrapper } from './style';

// TODO: Migrate to @engyalo/design-system Dialog component
const GenericModal: React.FC<IModal> = (props: IModal) => {
  const { open, title, children, hasCloseIcon, handleModal, isFullScreen, handleModalClose, ...rest } = props;
  const classNames = [isFullScreen ? 'isFullScreen' : '', hasCloseIcon ? 'hasCloseIcon' : ''].join(' ');

  const handleCloseButton = () => {
    handleModal?.();
    handleModalClose?.();
  };

  return (
    <Modal
      data-testid="genericModal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      onClose={handleModalClose}
      {...rest}
    >
      <Fade in={open}>
        <ModalWrapper className={classNames}>
          {title && (
            <ModalHeader>
              <Typography variant="h3" color="neutral.var90" data-testid="title">
                {title}
              </Typography>

              {hasCloseIcon && (
                <IconButton
                  data-testid="closeModalFullScreen"
                  onClick={handleCloseButton}
                  variant="text"
                  color="neutral"
                >
                  <i className="fa-regular fa-xmark fa-sm" />
                </IconButton>
              )}
            </ModalHeader>
          )}
          {children}
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default GenericModal;
