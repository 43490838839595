import React, { useCallback, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Switch } from '@engyalo/design-system';
import { RowsPerPage } from '../../../constants/pagination';
import { replaceFieldsTable } from '../../../helpers/replaceFieldsTable';
import { convertDatesTable } from '../../../helpers/formatterDates';
import { getVerifiedTemplates } from '../../../services/getVerifiedTemplates';
import { getVerifiedTemplateFullInfo } from '../../../services/getVerifiedTemplateFullInfo';
import { IOrder } from '../../../types/order';
import { updateTemplate } from '../../../services/updateVerifiedTemplate';
import { getDepartments } from '../../../services/getDepartments';
import { getUnits } from '../../../services/getUnits';
import { getDepartmentsListUnit } from '../../../services/getDepartmentsUnit';
import { ItemTable } from '.';
import { IDepartmentsListUnit, Template, TemplateInfo } from '../../../services/types';
import { logError } from '../../../services/Logger';
import { STORAGE_KEYS } from '../../../constants/defaultValues';

const DEFAULT_BOT_ID = 'delivery';

export enum SortVerifiedTemplates {
  template = 'Name',
  preview = 'BodyText',
  dateAdded = 'CreatedAt',
  enable = 'IsActiveSalesdesk',
}

const useVerifiedTemplatesManager = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [headerColumns, setHeaderColumns] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pagination, setPagination] = useState({ count: 100, offset: 0 });
  const [orderVerifiedHSMs, setOrderVerifiedHSMs] = useState<IOrder>('asc');
  const [orderByVerifiedHSMs, setOrderByVerifiedHSMs] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [hsmSelectedData, setHsmSelectedData] = useState<TemplateInfo>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showModalUnsavedChanges, setShowModalUnsavedChanges] = useState(false);
  const [errorUpdt, setErrorUpdt] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [inputText, setInputText] = useState('');
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const botId = localStorage.getItem(STORAGE_KEYS.PATH);
  const [teams, setTeams] = useState<IDepartmentsListUnit[]>([]);

  const handleCurrentPage = useCallback((_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);

    setPagination((old) => ({
      ...old,
      offset: (value - 1) * RowsPerPage,
    }));
  }, []);

  const disableVerifiedTemplate = async (item: Template) => {
    const teamIds = item.SharedWith.map((el) => el.id);
    try {
      await updateTemplate(item.ID, !item.IsActiveSalesdesk, teamIds);
      loadHSMs();
    } catch {
      setShowErrorModal(true);
    }
  };

  const handleTable = (templates: Template[]) => {
    setHeaderColumns(['template', 'sharedWith', 'preview', 'dateAdded', 'enable']);

    const values = templates.map((item) => {
      const finalDate = convertDatesTable(
        item.CreatedAt,
        languageLocalStorage || window.navigator.language,
        'templates'
      );
      const auxFinalText = finalDate.text.split(' ');

      return {
        _id: item.ID,
        template: item.Name,
        sharedWith: item.SharedWith.length ? (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '300px',
            }}
            dangerouslySetInnerHTML={{
              __html: item.SharedWith.map((el) => el.name).join(', '),
            }}
          />
        ) : (
          ''
        ),
        preview: (
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '500px',
            }}
            dangerouslySetInnerHTML={{
              __html: replaceFieldsTable(item.BodyText),
            }}
          />
        ),
        dateAdded: (
          <Typography whiteSpace="break-spaces">
            {typeof finalDate.time === 'number' && finalDate.text
              ? `${finalDate.time} ${t(`${auxFinalText[0]}`)}`
              : `${t(`${finalDate.date}`)}${t(`${auxFinalText[0]}`)} ${t(`${auxFinalText[1] || ''}`)}\n${
                  finalDate.time
                }`}
          </Typography>
        ),
        enable: (
          <Switch
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              disableVerifiedTemplate(item);
            }}
            checked={item.IsActiveSalesdesk}
          />
        ),
      };
    });
    setTableRows(values);
  };

  const loadHSMs = useCallback(
    async (term?: string, sortType: string = 'template', sortValue: number = 1, departmentId?: string) => {
      setLoading(true);
      setHsmSelectedData(undefined);
      try {
        setOrderByVerifiedHSMs(sortType ?? '');
        setOrderVerifiedHSMs(sortValue === -1 ? 'asc' : 'desc');
        let sortBy = 'template';

        sortBy = SortVerifiedTemplates[sortType as keyof typeof SortVerifiedTemplates];

        const propsGetTemplates = {
          Count: pagination.count,
          Offset: pagination.offset,
          accountId: botId || DEFAULT_BOT_ID,
          search: term,
          sort: sortBy,
          sortValue,
          departmentsIds: departmentId,
        };

        const {
          data: { Templates, Total },
        } = await getVerifiedTemplates(propsGetTemplates);

        setTotalItems(Total);
        if (Total) {
          handleTable(Templates);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setShowErrorModal(true);
        logError('error get verified templates', error);
      }
    },
    []
  );

  const getHSMSelected = async (id: string) => {
    const { data } = await getVerifiedTemplateFullInfo(id);

    setHsmSelectedData(data);
  };

  const handleClickItem = (item: ItemTable) => {
    setShowDrawer(true);
    getHSMSelected(item._id);
  };

  const handleChangeForm = (isChanged: boolean) => {
    setUnsavedChanges(isChanged);
  };

  const loadTeams = async () => {
    setLoading(true);
    try {
      const {
        data: { departments },
      } = await getDepartments();

      const userDepartments = departments;
      const {
        data: { units },
      } = await getUnits();
      const teamsList: IDepartmentsListUnit[] = [];

      for (const element of units) {
        const unit = element;
        if (unit.numDepartments) {
          // i'm disabling this rule in the specific line below because i need to get the departments by unit._id and inside the rocket i can only get this info accessing one by one, that's why i made in that way
          // eslint-disable-next-line no-await-in-loop
          const { data } = await getDepartmentsListUnit(unit._id);
          const list = data.departments.filter((item) => item.name !== 'bot');
          teamsList.push(...list);
        }
      }

      const finalTeams: IDepartmentsListUnit[] = [];
      userDepartments.forEach(({ _id }) => {
        teamsList.forEach((team) => {
          if (team._id === _id) {
            finalTeams.push(team);
          }
        });
      });
      setTeams(finalTeams);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logError('error get departments', error);
    }
  };

  useEffect(() => {
    loadHSMs();
    loadTeams();
  }, []);

  return {
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    loading,
    handleClickItem,
    setShowDrawer,
    showDrawer,
    hsmSelectedData,
    teams,
    unsavedChanges,
    setUnsavedChanges,
    handleChangeForm,
    showModalUnsavedChanges,
    setShowModalUnsavedChanges,
    errorUpdt,
    setErrorUpdt,
    showErrorModal,
    setShowErrorModal,
    inputText,
    setInputText,
    loadHSMs,
    orderVerifiedHSMs,
    orderByVerifiedHSMs,
  };
};

export default useVerifiedTemplatesManager;
