/* eslint-disable no-underscore-dangle */
import { useContext, useMemo, useRef } from 'react';
import { MessageType } from 'components/Messages';
import WebSocketService from 'services/Websocket';
import ChatsContext from 'contexts/chats/context';
import WebsocketContext from 'contexts/websocket/context';
import CannedMessagesContext from 'contexts/cannedMessages/context';
import WithFeatureFlag from 'hocs/WithFeatureFlag/withFeatureFlag';
import { flag } from 'hocs/WithFeatureFlag';
import { groupMessagesByDateStamp } from 'utils/messageUtils';
import MessagesList from 'components/MessagesList';
import ScrollToBottomButton from 'components/ScrollToBottomButton';
import ConversationExplorerAd from 'components/ConversationExplorerAd';
import { MILLIS_IN_ONE_DAY } from 'constants/';
import { CHAT_RETENTION_DAYS as CHAT_RETENTION_DAYS_DEFAULT } from 'constants/defaultValues';

import { ButtonArea, ChatContainer, FooterArea, HeaderArea, MainArea, MessagesArea, MessagesWrapper } from './styled';
import useChat from './hooks/useChat';
import Header from './Header/Header';
import SubHeader from './SubHeader';
import Assistant from './Assistant';
import QuickMessages from './QuickMessages/QuickMessages';
import ChatMessageStack from './InputBar/ChatMessageStack';
import DropArea from './DropArea';

type ChatProps = {
  chatId: string;
  webSocket: WebSocketService | null;
  isWsConnected: boolean;
};

const isDiscussion = false;

const Chat: React.FC<ChatProps> = (props) => {
  const { webSocket, isWsConnected, chatId } = props;
  const containerRef = useRef(null);
  const { messages, isLoading, sendMessage, retryMessage } = useChat({ chatId, webSocket });
  const groupedMessages = useMemo(() => groupMessagesByDateStamp(messages), [messages]);

  // Temporarily using the following contexts while their features are not completely migrated
  const { serviceSelected } = useContext(ChatsContext);
  const CHAT_RETENTION_DAYS = Number.parseInt(process.env.CHAT_RETENTION_DAYS || CHAT_RETENTION_DAYS_DEFAULT, 10);
  const phone = useMemo(() => serviceSelected.v.token.split(':').pop()!, [serviceSelected.v.token]);
  const isOldChat = useMemo(() => {
    if (!CHAT_RETENTION_DAYS) {
      return false;
    }

    const chatDate = new Date(serviceSelected.ts);
    const timeDifference = Date.now() - chatDate.getTime();
    return timeDifference > CHAT_RETENTION_DAYS * MILLIS_IN_ONE_DAY;
  }, [CHAT_RETENTION_DAYS, serviceSelected.ts]);

  const { showOnHoldHeader, discussionNotification, messagesList } = useContext(WebsocketContext);
  const { showQuickMessages } = useContext(CannedMessagesContext);

  const handleMessageClick = (message: MessageType) => {
    const { chat } = message;
    const hasError = chat && chat.error;

    if (hasError) {
      retryMessage(message);
    }
  };

  return (
    <ChatContainer data-testid="chatContainer">
      <HeaderArea>
        <Header
          openDrawer
          serviceSelected={serviceSelected}
          showOnHoldHeader={showOnHoldHeader}
          discussionNotification={discussionNotification}
        />
        <SubHeader />
      </HeaderArea>
      <DropArea isDiscussion={isDiscussion} sendMessage={sendMessage}>
        <MainArea>
          {isOldChat && <ConversationExplorerAd chatDate={serviceSelected.ts} phone={phone} />}
          {messages.length > 0 && !isOldChat && (
            <MessagesWrapper>
              {/* 
                Force component update when `serviceSelected._id` changes by using it as a key. 
                This ensures `containerRef` is refreshed, allowing scroll adjustments in the `StyledScrollToBottomButton`.
              */}
              <MessagesArea ref={containerRef} key={serviceSelected._id}>
                <MessagesList messages={groupedMessages} onMessageClick={handleMessageClick} />
              </MessagesArea>
              <ButtonArea>
                <ScrollToBottomButton containerRef={containerRef} deps={[messages]} />
              </ButtonArea>
            </MessagesWrapper>
          )}
        </MainArea>
        <FooterArea>
          {showQuickMessages && (
            <WithFeatureFlag
              featureFlag={flag.CHAT_COPILOT_ASSISTANT}
              WrappedComponent={<Assistant messages={messagesList} />}
              FalseComponent={<QuickMessages />}
            />
          )}
          <ChatMessageStack
            showQuickMessagesIcon
            isDiscussion={isDiscussion}
            sendMessage={sendMessage}
            isWsConnected={isWsConnected}
            isChatLoading={isLoading}
          />
        </FooterArea>
      </DropArea>
    </ChatContainer>
  );
};

export default Chat;
