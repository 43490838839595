import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { ChartsTotals } from './types';

export const getDashChartsTotals = async (
  startDate: string,
  endDate: string,
  departmentId?: string
): Promise<AxiosResponse<ChartsTotals>> => {
  const params = {
    departmentId,
    start: startDate,
    end: endDate,
  };

  return AxiosInstance.get('livechat/analytics/dashboards/charts/chats', {
    params,
  });
};
