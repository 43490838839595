import { useState, useEffect } from 'react';
import { Button, TextField } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setSelectedDepartment, setScheduleName, setDays, setTimezone } from 'redux/reducers/businessHoursReducer';
import { createSchedule, updateSchedule } from 'services/businessHours';
import { logError } from 'services/Logger';
import Drawer from 'components/Drawer';
import LoadingAnimation from 'components/AnimationLoading';
import { IBusinessHoursSettingsProps, IScheduleForm } from './types';
import Title from '../../Title/Title';
import MultiSelector from '../MultiSelector/MultiSelector';
import { getAvailableDays } from './constants';
import TimePicker from '../TimePicker/TimePicker';
import { DrawerBody, DrawerFooter, DrawerForm } from '../styles';
import { schemaBusinessHours } from './yupSchema';
import TimezoneSelector from '../TimezoneSelector';

const BusinessHoursSettings = ({ open, onClose, onSubmitted }: IBusinessHoursSettingsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const selectedSchedule = useAppSelector((state) => state.businessHours.selectedSchedule);
  const { id, name, timezone, departmentIds, days = [], workHours } = selectedSchedule;
  const departments = useAppSelector((state) => state.businessHours?.departments);
  const formattedDepartments =
    departments?.map((department) => ({
      value: department._id, // eslint-disable-line no-underscore-dangle
      label: department.name,
    })) || [];
  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IScheduleForm>({
    resolver: yupResolver(schemaBusinessHours),
  });
  const isEditing = !!id;
  const availableDays = getAvailableDays(t);
  const title = isEditing ? t('business-hours.drawer.editTitle') : t('business-hours.drawer.newTitle');
  const actionLabel = isEditing ? t('business-hours.actions.save') : t('business-hours.actions.create');
  const actionLabelComponent = isLoading ? <LoadingAnimation /> : actionLabel;

  const saveScheduleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch(setScheduleName(value));
  };

  const saveDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = e.target.value as unknown as [];
    dispatch(setSelectedDepartment(values));
  };

  const saveTimezone = (value: string) => {
    dispatch(setTimezone(value));
  };

  const saveDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = e.target.value as unknown as [];
    dispatch(setDays(values));
  };

  const handleOnClose = () => {
    clearErrors();
    onClose?.();
  };

  const onSubmit: SubmitHandler<IScheduleForm> = async () => {
    setIsLoading(true);

    try {
      if (isEditing) {
        await updateSchedule(selectedSchedule);
      } else {
        await createSchedule(selectedSchedule);
      }
      onSubmitted?.(selectedSchedule);
      handleOnClose();
    } catch (error) {
      logError('error createSchedule', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue('name', name);
    setValue('timezone', timezone);
    setValue('departmentIds', departmentIds);
    setValue('days', days);
    setValue('workHours', workHours);
  }, [name, timezone, departmentIds, days, workHours, setValue]);

  return (
    <Drawer open={open}>
      <Title title={title} onClose={handleOnClose} />
      <DrawerForm onSubmit={handleSubmit(onSubmit)}>
        <DrawerBody>
          {isEditing && <TextField label={t('business-hours.table.id')} value={id} disabled />}
          <TextField
            label={t('business-hours.name.label')}
            placeholder={t('business-hours.name.placeholder')}
            value={name}
            onChange={saveScheduleName}
            error={!!errors.name}
            helperText={t(errors.name?.message || '')}
            disabled={isEditing}
          />
          <MultiSelector
            items={formattedDepartments}
            selectedValue={departmentIds}
            itemLabel={t('business-hours.departmentSelector.label')}
            placeholder={t('business-hours.departmentSelector.placeholder')}
            onChange={saveDepartment}
            error={!!errors.departmentIds}
            helperText={t(errors.departmentIds?.message || '')}
          />
          <TimezoneSelector
            value={timezone}
            label={t('business-hours.timeZone.label')}
            placeholder={t('business-hours.timeZone.placeholder')}
            onChange={saveTimezone}
            error={!!errors.timezone}
            errorMessage={errors.timezone?.message}
          />
          <MultiSelector
            items={availableDays}
            selectedValue={days}
            itemLabel={t('business-hours.daySelector.label')}
            placeholder={t('business-hours.daySelector.placeholder')}
            onChange={saveDays}
            error={!!errors.days}
            helperText={t(errors.days?.message || '')}
          />
          <TimePicker />
        </DrawerBody>
        <DrawerFooter>
          <Button variant="contained" type="submit" disabled={isLoading}>
            {actionLabelComponent}
          </Button>
        </DrawerFooter>
      </DrawerForm>
    </Drawer>
  );
};

export default BusinessHoursSettings;
