/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getRoomInfo } from 'services/getRoomInfo';
import { saveRoomInfo } from 'services/saveRoomInfo';
import { useChatStore } from 'redux/reducers/chatReducer';
import { selectChatSelectedCannedMessages } from 'redux/selectors';
import useEnvironment from 'hooks/useEnvironments';
import { useAppSelector } from 'hooks/redux';
import { managementRoles } from 'constants/roles';
import ChatsContext from '../../../../../contexts/chats/context';
import WebsocketContext from '../../../../../contexts/websocket/context';
import CannedMessagesContext from '../../../../../contexts/cannedMessages/context';
import { createDiscussion } from '../../../../../services/createDiscussion';
import SelectAgentContext from '../../../../../contexts/selectAgentContext/context';
import { addAgentsToDiscussion } from '../../../../../services/addAgentsToDiscussion';
import AuthContext from '../../../../../contexts/auth/context';
import usePermissions from '../../../../../hooks/usePermissions';
import useAudio from './useAudio';

import { sendMessage as rocketChatSendMessage } from '../../../../../services/sendMessage';
import { MessageType } from '../../../../../types/message';
import useSegment from '../../../../../services/Analytics';
import { getLivechatRoomInfo } from '../../../../../services/getLivechatRoomInfo';
import { addGroupOwner } from '../../../../../services/addGroupOwner';
import { logError } from '../../../../../services/Logger';
import { SendMessage } from '../../hooks/useChat';

type CurrentMessages = {
  [index: string]: string;
};

const useInputMessage = (discussionId: string, isDiscussion: boolean, sendMessage?: SendMessage) => {
  const { t } = useTranslation();

  const { handleResumeOnHoldChat, handleErrorNewMessage, websocketIsConnect } = useContext(WebsocketContext);

  const { showQuickMessages, handleQuickMessages, cannedMessageSelected, resetCannedMessages, tabPressed, onClick } =
    useContext(CannedMessagesContext);

  const { agentSelected, showSelectAgent, handleSelectAgent } = useContext(SelectAgentContext);

  const {
    handleServiceSelected,
    initChat,
    serviceSelected,
    serviceLimitReached,
    disabledInputChat,
    loadDiscussionInfo,
    discussionOpened,
    emoji,
    setEmoji,
    userIsInDiscussion,
    handleAddErrorMessages,
    setServiceSelected,
  } = useContext(ChatsContext);

  const { audioRecorder, handleSendAudioState, setChatId, createAudioInstance, stopAudioInstance } =
    useAudio(sendMessage);
  const { getBasePath, getInstance } = useEnvironment();
  const instance = useMemo(() => getInstance(), [getInstance]);
  const basePath = useMemo(
    () => (process.env.REACT_APP_EPHEMERAL ? getBasePath().replace(instance, '') : '/'),
    [getBasePath, instance]
  );

  const { checkPermission } = usePermissions();

  const [currentMessages, setCurrentMessages] = useState<CurrentMessages>({});
  const [currentMessageDiscussion, setCurrentMessageDiscussion] = useState('');
  const { currentUserInfo } = useContext(AuthContext);
  const [placeholderInput, setPlaceholderInput] = useState('message');
  const [timerRecoder, setTimeRecorder] = useState<string>('00:00');
  const [isRecording, setRecording] = useState(false);
  const [audioDuration, setAudioDuration] = useState<NodeJS.Timer>();
  const [openErrorPermission, setErrorPermission] = useState(false);
  const [chatOnHOld, setChatIsOnHold] = useState(false);
  const [alignment, setAlignment] = useState('center');
  const { sendEvent, EVENT_NAMES } = useSegment();
  const focusDiv = useRef<HTMLInputElement>({} as HTMLInputElement);
  const chat_id = serviceSelected._id;
  const contactId = serviceSelected?.v?._id;
  const [disableInput, setDisableInput] = useState(true);
  const selectedCannedMessages = useAppSelector(selectChatSelectedCannedMessages);
  const { setSelectedCannedMessages, setRoomTagNames } = useChatStore();
  const selectedCannedMessageTags = selectedCannedMessages[chat_id]?.tags ?? [];

  const canCreateNewDiscussions = checkPermission(managementRoles);

  const getCurrentMessage = useCallback(() => currentMessages[chat_id] || '', [chat_id, currentMessages]);
  const setCurrentMessage = useCallback(
    (value: string) => setCurrentMessages({ ...currentMessages, [chat_id]: value }),
    [chat_id, currentMessages]
  );

  const getTexts = (value: string) => {
    const textBefore = value.substring(0, focusDiv.current.selectionStart ?? 0);
    const textAfter = value.substring(focusDiv.current.selectionStart ?? 0, value.length);

    return { textBefore, textAfter };
  };

  const getMentionedUsers = () => {
    const messageAux = !isDiscussion ? getCurrentMessage() : currentMessageDiscussion;
    return messageAux
      .split(' ')
      .filter((el) => el.startsWith('@'))
      .map((element) => element.replace('@', ''));
  };

  const clearInputMessage = () => {
    setCurrentMessage('');
    setCurrentMessageDiscussion('');
    resetCannedMessages();
  };

  const sendNewMessage = async (message: string, chatId: string) => {
    try {
      if (message) {
        await rocketChatSendMessage(message, chatId);
      }
    } catch (error) {
      const newErrorMessage = {
        id: uuidv4(),
        date: new Date(),
        msg: message,
        rid: chatId,
        hasError: true,
        u: {
          _id: currentUserInfo._id,
          name: currentUserInfo.name,
          username: currentUserInfo.username,
        },
        userId: currentUserInfo._id,
        username: currentUserInfo.username,
        from: currentUserInfo.name,
      } as MessageType;
      handleErrorNewMessage(newErrorMessage);
      handleAddErrorMessages(newErrorMessage);
    }
  };

  const clearSelectedCannedMessage = async () => {
    if (chat_id) {
      setSelectedCannedMessages({
        ...selectedCannedMessages,
        [chat_id]: null,
      });
    }
  };

  const handleForceAddTagsFromCannedMessage = async () => {
    const cannedMessage = selectedCannedMessages[chat_id];
    if (cannedMessage) {
      const {
        data: { room = {} },
      } = await getRoomInfo(chat_id);
      const currentRoomTags = room.tags ?? [];
      const tagsFromCannedMessage = cannedMessage.tags ?? [];
      const uniqueTags = new Set<string>([...currentRoomTags, ...tagsFromCannedMessage]);
      const tags = Array.from(uniqueTags);
      const paramsSaveRoomInfo = {
        chatId: chat_id,
        contactId,
        tags,
        uuid: uuidv4(),
      };
      saveRoomInfo(paramsSaveRoomInfo);
      clearSelectedCannedMessage();
      setRoomTagNames(tags);
    }
  };

  const handleSendMessage = async (msg: string) => {
    clearInputMessage();
    try {
      if (websocketIsConnect) {
        const {
          data: { room },
        } = await getLivechatRoomInfo(serviceSelected?.v?.token, chat_id);

        const { servedBy, open } = room;
        if (!servedBy && open) {
          await initChat();
          sendEvent(EVENT_NAMES.USER_START_A_SERVICE, { queue: 'pending' });
        }

        if (chatOnHOld) {
          handleServiceSelected({});
          handleResumeOnHoldChat(chat_id);
          setChatIsOnHold(false);
          sendEvent(EVENT_NAMES.USER_START_A_SERVICE, { queue: 'onHold' });
        }

        if (isDiscussion && discussionId === '') {
          const members = getMentionedUsers();
          const isAsignee = currentUserInfo._id === servedBy?._id;

          // Conversation could have been started by a monitor/manager; include the agent
          if (!isAsignee && canCreateNewDiscussions) {
            members.push(servedBy?.username);
          }

          const {
            data: { discussion },
          } = await createDiscussion(chat_id, `${serviceSelected.fname}-discussion`, currentMessageDiscussion, members);

          if (!isAsignee) {
            // So that the agent can later perform actions on the room, like adding more members
            await addGroupOwner(discussion.rid, servedBy._id);
          }

          if (discussion) {
            loadDiscussionInfo(discussion.prid);
          }
        } else if (isDiscussion) {
          await sendNewMessage(currentMessageDiscussion, discussionId);
          const mentionedUsers = getMentionedUsers();
          if (mentionedUsers.length) {
            await addAgentsToDiscussion(discussionId, mentionedUsers);
          }
        } else if (!servedBy || currentUserInfo._id === servedBy._id) {
          sendEvent(EVENT_NAMES.USER_SEND_A_TEXT, {
            words: getCurrentMessage().split(' ').length,
          });

          sendMessage?.({
            kind: 'text',
            payload: {
              body: msg,
            },
          });
        } else {
          setServiceSelected(room);
        }
      }
    } catch (error) {
      logError('error send message', error);
    }
  };

  const playSound = () => {
    const audio = new Audio(`${basePath}sounds/beep.mp3`);
    audio.play();
  };

  const startRecordingAudio = async () => {
    const audioState = await createAudioInstance();
    if (audioState) {
      playSound();
      setRecording(true);
      const startTime = new Date();
      setAudioDuration(
        setInterval(() => {
          const now = new Date();
          const distance = (now.getTime() - startTime.getTime()) / 1000;
          const minutes = Math.floor(distance / 60);
          const seconds = Math.floor(distance % 60);
          setTimeRecorder(`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
        }, 1000)
      );
    } else {
      setErrorPermission(true);
    }
  };

  const sendAudio = () => {
    stopAudioInstance();
    playSound();

    if (isDiscussion) {
      setChatId(discussionId);
    } else {
      setChatId(chat_id);
    }

    clearInterval(audioDuration);
    setTimeRecorder('00:00');
    setRecording(false);
    handleSendAudioState(true);

    if (chatOnHOld) {
      handleServiceSelected({});
      handleResumeOnHoldChat(chat_id);
      setChatIsOnHold(false);
    }

    const timeSplit = timerRecoder.split(':');
    const seconds = +timeSplit[0] * 60 + +timeSplit[1];

    sendEvent(EVENT_NAMES.USER_RECORD_A_AUDIO_TO_CUSTOMER, {
      seconds,
    });
  };

  const cancelAudio = () => {
    audioRecorder.cancel();
    playSound();
    clearInterval(audioDuration);
    setTimeRecorder('00:00');
    setRecording(false);
  };

  const changeSendButtonAlignment = () => {
    const element = document.getElementById('messageInput');

    if (element) {
      if (element.clientHeight > 23) {
        return 'self-end';
      }
      return 'center';
    }
    return 'center';
  };

  useEffect(() => {
    if (!showQuickMessages && getCurrentMessage()) {
      if (focusDiv?.current?.focus) {
        focusDiv.current.focus();
      }
    }
  }, [showQuickMessages, getCurrentMessage]);

  useEffect(() => {
    if (agentSelected) {
      const currentMessage = isDiscussion ? currentMessageDiscussion : getCurrentMessage();
      const partialMentionRegex = /(?<partialMention>@[0-9a-zA-Z-_.]*)$/;
      const found = currentMessage.match(partialMentionRegex);
      const partialMention = found?.groups?.partialMention;

      if (partialMention) {
        const messageWithMention = currentMessage.replace(partialMentionRegex, `@${agentSelected} `);
        if (isDiscussion) {
          setCurrentMessageDiscussion(messageWithMention);
        } else {
          setCurrentMessage(messageWithMention);
        }
      }
    }
  }, [agentSelected, currentMessageDiscussion, getCurrentMessage, isDiscussion, setCurrentMessage]);

  useEffect(() => {
    if (cannedMessageSelected) {
      setCurrentMessage(cannedMessageSelected);
    }
  }, [cannedMessageSelected, tabPressed, onClick]);

  useEffect(() => {
    setChatIsOnHold(serviceSelected.onHold);
  }, [serviceSelected]);

  useEffect(() => {
    if (chatOnHOld) {
      setPlaceholderInput('sendMessageToResumeChat');
    } else if (serviceLimitReached && !serviceSelected.servedBy) {
      setPlaceholderInput('serviceLimitReachedMessageInput');
    } else if (!serviceSelected.servedBy && !serviceLimitReached) {
      setPlaceholderInput('sendMessageToStartChat');
    } else if (serviceSelected?.servedBy?._id !== currentUserInfo._id && !discussionOpened) {
      // nothing to do
    } else if (!serviceSelected.open) {
      setPlaceholderInput('finishedChat');
    } else if (serviceSelected.servedBy?._id !== currentUserInfo._id && !userIsInDiscussion && !discussionOpened) {
      setPlaceholderInput('DontPossibleSendMessage');
    } else {
      setPlaceholderInput('message');
    }
  }, [chatOnHOld, serviceLimitReached, serviceSelected, currentUserInfo]);

  useEffect(() => {
    if (!getCurrentMessage() && showQuickMessages) {
      handleQuickMessages();
    }
    if (!currentMessageDiscussion && showSelectAgent) {
      handleSelectAgent();
    }
  }, [getCurrentMessage, currentMessageDiscussion]);

  useEffect(() => {
    if (emoji) {
      const { textBefore, textAfter } = getTexts(discussionOpened ? currentMessageDiscussion : getCurrentMessage());
      if (discussionOpened) {
        setCurrentMessageDiscussion(`${textBefore} ${emoji} ${textAfter}`);
      } else {
        setCurrentMessage(`${textBefore} ${emoji} ${textAfter}`);
      }
      setEmoji('');
    }
  }, [emoji]);

  return {
    get currentMessage() {
      return getCurrentMessage();
    },
    setCurrentMessage,
    handleSendMessage,
    startRecordingAudio,
    sendAudio,
    cancelAudio,
    timerRecoder,
    isRecording,
    openErrorPermission,
    setErrorPermission,
    chatOnHOld,
    translation: t,
    alignment,
    setAlignment,
    changeSendButtonAlignment,
    placeholderInput,
    serviceLimitReached,
    disabledInputChat: isDiscussion && canCreateNewDiscussions ? false : disabledInputChat,
    focusDiv,
    discussionOpened,
    currentMessageDiscussion,
    setCurrentMessageDiscussion,
    userIsInDiscussion,
    disableInput,
    setDisableInput,
    clearSelectedCannedMessage,
    handleForceAddTagsFromCannedMessage,
    selectedCannedMessageTags,
  };
};

export default useInputMessage;
