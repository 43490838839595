import { DASHBOARD_ANALYTICS_KEYS } from '../../../constants/defaultValues';
import { getAgentsByDepartments } from '../../../services/getAgentsByDepartments';
import { getUsersList } from '../../../services/getUsersList';
import { NOT_USERS } from './useServiceDashboard';

export const loadAgentslist = async (selectedTeam: string) => {
  const {
    data: { users },
  } = await getUsersList('');
  const { data: agents } = await getAgentsByDepartments(selectedTeam);

  const onlyAgentUsers = users.filter(
    ({ type, roles, username }) =>
      type &&
      !NOT_USERS.includes(type) &&
      roles &&
      roles.includes(DASHBOARD_ANALYTICS_KEYS.LIVECHAT_AGENT_ROLE) &&
      username !== DASHBOARD_ANALYTICS_KEYS.YALO_SYSTEM_USER_ADMIN
  );

  const agentsByDepartment = onlyAgentUsers.filter(({ _id }) => agents.agents.find((agt) => agt.agentId === _id));

  return agentsByDepartment;
};
