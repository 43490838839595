import { Stack } from '@mui/material';
import { IconButton, styled } from '@engyalo/design-system';

export const Container = styled(Stack)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 0;
  margin: 2.4rem 0;
  border-bottom: 1px solid ${theme.palette.divider};

  .MuiFormControlLabel-root {
    margin-left: 0px;
  }

  ${theme.breakpoints.down('sm')} {
    margin: 1.6rem 0;
    gap: 1.6rem;
  }
`
);

export const NotificationStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  padding: 0px;
  width: 100%;
  gap: 16px;
`;

export const SwitchStack = styled(Stack)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: flex-start;
  gap: 2rem;

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  ${theme.breakpoints.down('sm')} {
    gap: 0.8rem;
  }
`
);

export const Icon = styled(IconButton)`
  cursor: pointer;
  align-self: center;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  :hover {
    background-color: transparent;
  }

  svg {
    width: inherit;
    height: inherit;
  }
`;

export const ContentStack = styled(Stack)`
  padding: 0px;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
`;
