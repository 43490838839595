import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MessagesContainer = styled('div')`
  position: relative;
  background-color: ${({ theme }) => theme.palette.neutral.var10};
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  overflow-y: scroll;
  flex: 1 1 0;
  min-width: 0;
`;

export const MessagesGroup = styled('div')`
  background-color: inherit;

  & > :last-child {
    margin-bottom: 2rem;
  }
`;

export const DateContainer = styled('div')`
  z-index: 1;
  background-color: inherit;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-top: 2rem;
  padding-bottom: 1rem;

  > * {
    order: 2;
  }

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 0.1rem solid black;
    height: 0.1rem;
    margin: 0 1rem;
    border-color: ${({ theme }) => theme.palette.neutral.var50};
  }

  &::before {
    order: 1;
  }

  &::after {
    order: 3;
  }
`;

export const ConversationDate = styled(Typography)`
  background-color: inherit;
  border-color: ${({ theme }) => theme.palette.neutral.var70};
`;

export const ConversationHeader = styled('div')`
  padding: 0.6rem 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: '0.1rem solid ${({ theme }) => theme.palette.neutral.var20}';
`;

export const ConversationContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
export const ConversationBubbleWrapper = styled('div')`
  // Temporary solution to handle text overflow
  // until the MessageBubble component of YDS is reviewed
  overflow-wrap: anywhere;
`;
