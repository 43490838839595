import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { AgentTotalizer } from './types';

export const getDashAgentTotalizer = async (
  startDate: string,
  endDate: string,
  departmentId?: string
): Promise<AxiosResponse<AgentTotalizer>> => {
  const params = {
    departmentId,
    start: startDate,
    end: endDate,
  };

  return AxiosInstance.get('livechat/analytics/dashboards/agents-productivity-totalizers', {
    params,
  });
};
