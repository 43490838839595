import { getDashChartsTotals } from '../../../services/dashChartsTotals';
import { getDashAgentTotalizer } from '../../../services/dashAgentTotalizer';
import { isBusiestTime, Totalizer } from '../../../services/types';
import { convertTime12to24 } from '../../../helpers/formatterDates';
import { getLanguage } from '../../../constants/defaultValues';

export interface IConversationStatus {
  totalNew: string;
  awaitingService: string;
  inService: string;
  finishedToday: string;
  onHold?: string;
  busiestTime: string;
}

export const getConversationsStats = async (
  dateStart: string,
  dateEnd: string,
  teamId: string,
  showOnHold: boolean
) => {
  const conversationStatus: IConversationStatus = {} as IConversationStatus;
  const { data: dataCharts } = await getDashChartsTotals(dateStart, dateEnd, teamId);
  const { data: dataBusiestTime } = await getDashAgentTotalizer(dateStart, dateEnd, teamId);
  const systemLanguage = getLanguage();
  const getBusiestTime = dataBusiestTime.totalizers.reduce((acc, currentValue) => {
    if (isBusiestTime(currentValue)) {
      acc.push(currentValue);
    }
    return acc;
  }, [] as Totalizer[]);

  const [{ value: busiestTime }] = getBusiestTime;

  conversationStatus.totalNew = `${dataCharts.open + dataCharts.closed + dataCharts.onhold + dataCharts.queued}`;
  conversationStatus.awaitingService = `${dataCharts.queued}`;
  conversationStatus.inService = `${dataCharts.open + dataCharts.onhold}`;
  conversationStatus.finishedToday = `${dataCharts.closed}`;
  conversationStatus.onHold = showOnHold ? `${dataCharts.onhold}` : undefined;
  conversationStatus.busiestTime = busiestTime === '- -' ? '-' : convertTime12to24(busiestTime, systemLanguage);

  return conversationStatus;
};
