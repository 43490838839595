import { convertDateToSeparator } from 'helpers/formatterDates';
import { OutgoingMessageChatMessage } from '@engyalo/salesdesk-bff-ts-client';

export const groupMessagesByDateStamp = (
  conversations: Array<OutgoingMessageChatMessage>
): Record<string, Array<OutgoingMessageChatMessage>> =>
  conversations.reduce<Record<string, Array<OutgoingMessageChatMessage>>>(
    (accumulatedConversations, currentConversation) => {
      const dateStamp = convertDateToSeparator(Number(currentConversation.timestamp));

      if (!accumulatedConversations[dateStamp]) {
        return { ...accumulatedConversations, [dateStamp]: [currentConversation] };
      }

      accumulatedConversations[dateStamp].push(currentConversation);

      return accumulatedConversations;
    },
    {}
  );
