import React from 'react';
import { IconLoadingRequest } from './style';
import { IAnimationLoading } from './types';

const LoadingAnimation: React.FC<IAnimationLoading> = (props: IAnimationLoading) => {
  const { color } = props;
  return <IconLoadingRequest data-testid="LoadingAnimationTestId" fill={color} />;
};

export default LoadingAnimation;
