import { AnalyticsSnippet } from '@segment/analytics-next';

export interface AnalyticsWindow extends Window {
  analytics: AnalyticsSnippet;
}

export interface EventMessageProperties {
  type?: string;
  size?: number;
  seconds?: number;
  words?: number;
  queue?: string;
  tabSelected?: string;
}

export const EVENT_NAMES = {
  USER_LOGIN: 'Login',
  USER_LOGOUT: 'Logout',
  USER_CHANGE_STATUS_ONLINE: 'Change status online',
  USER_CHANGE_STATUS_OFFLINE: 'Change status offline',
  TABS_SELECTED: 'Tabs selected',
  USER_SELECTED_CONVERSATION: 'User selected conversation',
  USER_SEND_A_FILE: 'User send a file',
  USER_CLICKED_ON_PUT_ON_HOLD: 'User clicked on put on hold',
  USER_CLICKED_ON_SEARCH_THIS_CONVERSATION: 'User clicked on search this conversation',
  USER_CLICKED_ON_HISTORY_CONVERSATION: 'User clicked on history conversation',
  USER_CLICKED_ON_CLOSE_CONVERSATION: 'User clicked on close conversation',
  USER_CLICKED_ON_TRANSFER_TO_AGENT: 'User clicked on transfer to agent',
  USER_CLICKED_ON_TRANSFER_TO_TEAM: 'User clicked on transfer to team',
  USER_CLICKED_ON_INTERNAL_DISCUSSION: 'User clicked on internal discussion',
  USER_CHECK_CUSTOMER_INFO: 'User check customer info',
  USER_COPY_A_TICKET_ID: 'User copy a ticket id',
  USER_CLICKED_ON_THE_QUICK_MESSAGES: 'User clicked on the quick messages',
  USER_PUT_TAG_IN_CHAT: 'User put tag in chat',
  USER_RECORD_A_AUDIO_TO_CUSTOMER: 'User record a audio to customer',
  USER_SEND_A_TEXT: 'User send a text',
  USER_SEND_A_MESSAGE: 'User send a message',
  USER_START_A_SERVICE: 'User start a service',
  CONVERSATION_SELECTED: 'Conversations selected',
  USER_CLICKED_ON_KEBABI_MENU: 'User clicked on kebabi menu',
};

export const TAB_SELECTED_TYPE = {
  YOUR_CONVERSATIONS: 'YourConversations',
  ALL_CONVERSATIONS: 'AllConversations',
};

export const QUEUE_CONVERSATION_TYPE = {
  IN_ATTENDANCE: 'inAttendance',
  PENDING: 'pending',
  CLOSED_TODAY: 'closedToday',
  ON_HOLD: 'onHold',
};
