import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton } from '@engyalo/design-system';
import { OutgoingMessageChatMessage } from '@engyalo/salesdesk-bff-ts-client';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/system';
import { convertSeparatorToDate, formatDate } from 'helpers/formatterDates';
import ChatMessage from 'components/Messages/ChatMessage';

import {
  ConversationContainer,
  ConversationHeader,
  ConversationDate,
  DateContainer,
  MessagesContainer,
  MessagesGroup,
} from './styled';

interface IConversationMenuItem {
  key: string;
  disabled?: boolean;
  component?: 'a' | 'p' | 'span' | 'li';
  href?: string;
  download?: string;
  icon?: string;
  text: string;
  onClick?: () => void;
}

interface IConversationProps {
  headerName?: string;
  messagesHistory?: Record<string, Array<OutgoingMessageChatMessage>>;
  menuItems?: IConversationMenuItem[];
  sx?: SxProps<Theme>;
}

type ChatMenuProps = Pick<IConversationProps, 'menuItems'> & {
  anchor: null | HTMLElement;
  handleClose: () => void;
};

const ChatMenu: React.FC<ChatMenuProps> = ({ menuItems, anchor, handleClose }) => (
  <Menu id="chat-menu" open={!!anchor} anchorEl={anchor} onClose={handleClose}>
    {menuItems?.map(({ key, disabled, component = 'li', href, download, icon, text, onClick }) => (
      <MenuItem key={key} disabled={disabled} component={component} href={href} download={download} onClick={onClick}>
        <ListItemIcon>
          <i className={icon} />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </MenuItem>
    ))}
  </Menu>
);

export const Conversation: React.FC<IConversationProps> = ({ headerName, messagesHistory = {}, sx, menuItems }) => {
  const { i18n, t } = useTranslation();
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  return (
    <ConversationContainer sx={sx}>
      <ConversationHeader>
        <Stack direction="row" spacing="1.4rem">
          <Avatar variant="icon" icon="nonBinary" size="2x" />
          <Typography variant="h2">{t('conversation.header', { author: headerName })}</Typography>
        </Stack>
        {menuItems?.length ? (
          <IconButton
            data-testid="conversation-menu"
            onClick={({ currentTarget }) => setAnchor(currentTarget)}
            variant="text"
            color="neutral2"
            size="medium"
          >
            <i className="fa fa-solid fa-ellipsis-vertical" />
          </IconButton>
        ) : null}
        <ChatMenu anchor={anchor} handleClose={() => setAnchor(null)} menuItems={menuItems} />
      </ConversationHeader>
      <MessagesContainer>
        {Object.keys(messagesHistory).map((dateSeparator) => (
          <MessagesGroup key={dateSeparator}>
            <DateContainer>
              <ConversationDate variant="h4">
                {formatDate(convertSeparatorToDate(dateSeparator), i18n.language)}
              </ConversationDate>
            </DateContainer>
            {messagesHistory[dateSeparator].map((chat) => (
              <ChatMessage key={chat.id} chat={chat} />
            ))}
          </MessagesGroup>
        ))}
      </MessagesContainer>
    </ConversationContainer>
  );
};
