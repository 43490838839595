import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Tooltip } from '@engyalo/design-system';
import { formatDate } from 'constants/defaultValues';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import { logError } from 'services/Logger';
import { IRoom } from '../../types/room';
import { getChatsFilter } from '../../services/getChatsFilter';
import { RowsPerPage } from '../../constants/pagination';
import { getDepartments } from '../../services/getDepartments';
import { getLiveChatUsersAgent } from '../../services/getLivechatUsersAgt';
import { IOrder } from '../../types/order';
import { Department, User } from '../../services/types';
import { FilterProps, IFilterChats } from '../../components/ChatsAdvancedFilters';
import ChatManagerContext, { IChatTableItem, SortChatsManagerHome } from './context';

const ChatManagerProvider: React.FC = (props) => {
  const { children } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const timeout = 60000; // 60 seconds
  const [lastUpdate, setLastUpdate] = useState('');
  const [chats, setChats] = useState<Array<IRoom>>([]);
  const [reportRows, setReportRows] = useState<Array<IChatTableItem>>([]);
  const [tableRows, setTableRows] = useState<Array<IChatTableItem>>([]);
  const [totalItems, setTotalItems] = useState<number>(-1);
  const [pagination, setPagination] = useState({ count: RowsPerPage, offset: 0, currentPage: 1 });
  const [chatId, setChatId] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [contactName, setContactName] = useState('');
  const [ticket, setTicket] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showError, setShowModalError] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const [anchorElKebab, setAnchorElKebab] = useState<HTMLDivElement | null>(null);
  const [currentAvancedFilters, setCurrentAdvancedFilters] = useState<IFilterChats>({});
  const [departmentsState, setDepartments] = useState<Department[]>([]);
  const [agents, setAgents] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [orderChat, setOrder] = useState<IOrder>('asc');
  const [orderByChat, setOrderBy] = useState<SortChatsManagerHome>(SortChatsManagerHome.lastMessage);
  const [filterDateBy, setFilterDateBy] = useState<string>('');
  const loadChatsInterval = useRef<NodeJS.Timeout | null>(null);

  const handleCurrentPage = useCallback((_: React.ChangeEvent<unknown>, value: number) => {
    setPagination((old) => ({
      ...old,
      currentPage: value,
      offset: (value - 1) * RowsPerPage,
    }));
  }, []);

  const handleTable = useCallback(
    (list?: Array<IRoom>, isReport = false) => {
      const listValues = list || chats;
      const mapTableRow = (item: IRoom) => ({
        _id: item._id,
        open: !!item.open,
        ticket: item?.livechatData?.ticket ?? 'Flow',
        contact: item.fname,
        team: (
          <Tooltip title={<div style={{ textAlign: 'justify' }}>{item?.department?.name}</div>}>
            <span>{item?.department?.name}</span>
          </Tooltip>
        ),
        attendedBy: item.servedBy ? item.servedBy.username : '',
        startedAt: formatDate(item.ts, language),
        lastMessage: formatDate(item.lastMessage._updatedAt, language),
        closedAt: item.closedAt ? formatDate(item.closedAt, language) : '',
        situation: item.open ? capitalizeFirstLetter(t('opened')) : capitalizeFirstLetter(t('closed')),
        teamName: item?.department?.name,
      });

      if (isReport) {
        const rows = listValues.map((item) => ({
          ...mapTableRow(item),
          tags: item.tags?.join(),
          phone: item.v.token.split(':')[1],
        }));

        setReportRows(rows);
      } else {
        setTableRows(listValues.map(mapTableRow));
      }
    },
    [chats, language, t]
  );

  const loadChats = useCallback(
    async (
      filters?: IFilterChats,
      sortType?: SortChatsManagerHome,
      sortValue: number = -1,
      isReport = false,
      resetPagination: boolean = true
    ) => {
      const paginationFilter = {
        count: resetPagination ? RowsPerPage : pagination.count,
        offset: resetPagination ? 0 : pagination.offset,
      };
      setOrderBy(sortType ?? SortChatsManagerHome.lastMessage);
      setOrder(sortValue === -1 ? 'asc' : 'desc');
      const sortBy = sortType ?? SortChatsManagerHome.lastMessage;
      setLoading(true);
      const currentFilters = filters || currentAvancedFilters;

      const agentsIds = currentFilters?.agentId ? [currentFilters.agentId] : undefined;

      let customFieldsSelecteds = currentFilters?.customFields;
      if (ticket) {
        customFieldsSelecteds = { ...customFieldsSelecteds, ticket };
      }
      try {
        const chatParams = {
          tags: currentFilters?.tags,
          agentIds: agentsIds,
          departmentId: currentFilters?.departmentId,
          roomName: contactName || undefined,
          onHold: currentFilters?.onhold,
          offset: paginationFilter.offset,
          count: isReport ? 0 : paginationFilter.count,
          iniDate: startDate,
          finalDate: endDate,
          customFields: customFieldsSelecteds,
          sort: sortBy.toString(),
          sortValue,
          filterDateBy,
        };

        const {
          data: { total, rooms },
        } = await getChatsFilter(chatParams);
        if (isReport) {
          handleTable(rooms, true);
        } else {
          setTotalItems(total);
          setChats(rooms);
          handleTable(rooms);
        }
        if (resetPagination) {
          setPagination({ count: RowsPerPage, offset: 0, currentPage: 1 });
        }
        setLastUpdate(format(new Date(), 'PPpp'));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [
      pagination.count,
      pagination.offset,
      currentAvancedFilters,
      ticket,
      contactName,
      startDate,
      endDate,
      filterDateBy,
      handleTable,
    ]
  );

  const handleClickItem = (item?: IChatTableItem) => {
    if (item?._id) {
      setChatId(item._id);
      setShowDrawer(true);
    }
  };

  const handleShowDrawer = () => {
    setShowDrawer((state) => !state);
  };

  const handleShowError = () => {
    setShowModalError((state) => !state);
  };
  const handleClickAvancedFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setShowAdvancedFilters(true);
  };

  const handleCloseAdvancedFilter = () => {
    setAnchorEl(null);
    setShowAdvancedFilters(false);
  };

  const handleOpenKebab = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElKebab(event.currentTarget);
  };

  const removeFilter = (removeItem: string) => {
    const newAdvancedFilters = { ...currentAvancedFilters };
    if (Object.keys(newAdvancedFilters).includes(removeItem)) {
      delete newAdvancedFilters[removeItem as keyof IFilterChats];
    } else if (newAdvancedFilters.customFields && Object.keys(newAdvancedFilters.customFields).includes(removeItem)) {
      delete newAdvancedFilters.customFields[removeItem];
      if (Object.keys(newAdvancedFilters.customFields).length === 0) {
        delete newAdvancedFilters.customFields;
      }
    }
    setCurrentAdvancedFilters(newAdvancedFilters);
    loadChats(newAdvancedFilters);
  };

  const clearFilters = () => {
    setCurrentAdvancedFilters({});
    loadChats();
    setShowAdvancedFilters(false);
  };
  const getFilterPatch = (filterName: string, filterValue?: string): Partial<IFilterChats> => {
    const situationReducers: { [key: string]: Partial<IFilterChats> } = {
      open: { open: true },
      closed: { open: false },
      onhold: { onhold: true },
    };
    const filterReducers: { [key: string]: () => Partial<IFilterChats> } = {
      tags: () => ({ tags: filterValue ? filterValue.split(';') : [] }),
      attendedBy: () => (filterValue !== 'allOptions' ? { agentId: filterValue } : {}),
      teams: () => (filterValue !== 'allOptions' ? { departmentId: filterValue } : {}),
      situation: () => (filterValue ? situationReducers[filterValue] : {}),
    };
    return filterReducers[filterName] ? filterReducers[filterName]() : {};
  };

  const handleFilterChats = (filters: FilterProps[]) => {
    const defaultFilters = new Set(['tags', 'attendedBy', 'teams', 'situation']);
    const chatFilters = filters.reduce<IFilterChats>((prev, { selectFilter, value }) => {
      if (!selectFilter) {
        return { ...prev };
      }
      if (defaultFilters.has(selectFilter)) {
        const patch = getFilterPatch(selectFilter, value);
        return { ...prev, ...patch };
      }
      const { customFields = {} } = prev;
      return {
        ...prev,
        customFields: {
          ...customFields,
          ...(value ? { [selectFilter]: value } : {}),
        },
      };
    }, {});
    loadChats(chatFilters);
    setCurrentAdvancedFilters(chatFilters);
  };

  // Load chats every x time
  useEffect(() => {
    loadChatsInterval.current = setInterval(() => {
      loadChats(undefined, orderByChat, orderChat === 'asc' ? -1 : 1, undefined, false);
    }, timeout);

    return () => {
      if (loadChatsInterval.current) {
        clearInterval(loadChatsInterval.current);
      }
    };
  }, [orderByChat, orderChat, loadChats]);

  const getAgents = async () => {
    try {
      const {
        data: { users },
      } = await getLiveChatUsersAgent();
      setAgents(users.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));
    } catch (error) {
      logError('Error while fetching agents', error);
    }
  };

  const getListDepartments = async () => {
    try {
      const {
        data: { departments },
      } = await getDepartments();
      setDepartments(
        departments.sort((a: Department, b: Department) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      );
    } catch (error) {
      logError('Error while fetching departments', error);
    }
  };

  useEffect(() => {
    (async () => {
      loadChats(undefined, orderByChat, orderChat === 'asc' ? -1 : 1);
    })();
  }, [contactName, ticket, startDate, endDate, filterDateBy]);

  useEffect(() => {
    (async () => {
      loadChats(undefined, orderByChat, orderChat === 'asc' ? -1 : 1, undefined, false);
    })();
  }, [pagination.currentPage]);

  useEffect(() => {
    getAgents();
    getListDepartments();
  }, []);

  useEffect(() => {
    const auxIniDate = startDate?.getTime();
    const auxEndDate = endDate?.getTime();
    if (auxEndDate && auxIniDate && auxEndDate < auxIniDate) {
      setEndDate(null);
      handleShowError();
    }
  }, [endDate]);

  const memoizedValues = useMemo(
    () => ({
      currentPage: pagination.currentPage,
      tableRows,
      totalItems,
      reportRows,
      handleCurrentPage,
      handleClickItem,
      chatId,
      showDrawer,
      handleShowDrawer,
      setContactName,
      setTicket,
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      ticket,
      contactName,
      language,
      handleShowError,
      showError,
      showAdvancedFilters,
      setShowAdvancedFilters,
      handleCloseAdvancedFilter,
      handleClickAvancedFilter,
      anchorEl,
      anchorElKebab,
      handleOpenKebab,
      handleFilterChats,
      currentAvancedFilters,
      agents,
      departmentsState,
      removeFilter,
      clearFilters,
      loading,
      loadChats,
      orderChat,
      orderByChat,
      filterDateBy,
      setFilterDateBy,
      lastUpdate,
    }),
    [
      pagination.currentPage,
      tableRows,
      totalItems,
      reportRows,
      handleCurrentPage,
      handleClickItem,
      chatId,
      showDrawer,
      handleShowDrawer,
      setContactName,
      setTicket,
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      ticket,
      contactName,
      language,
      handleShowError,
      showError,
      showAdvancedFilters,
      setShowAdvancedFilters,
      handleCloseAdvancedFilter,
      handleClickAvancedFilter,
      anchorEl,
      anchorElKebab,
      handleOpenKebab,
      handleFilterChats,
      currentAvancedFilters,
      agents,
      departmentsState,
      removeFilter,
      clearFilters,
      loading,
      loadChats,
      orderChat,
      orderByChat,
      filterDateBy,
      setFilterDateBy,
      lastUpdate,
    ]
  );
  return <ChatManagerContext.Provider value={memoizedValues}>{children}</ChatManagerContext.Provider>;
};

export default ChatManagerProvider;
