import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const getDashChartsPerAgent = async (
  startDate: string,
  endDate: string,
  departmentId?: string
): Promise<AxiosResponse> => {
  const params = {
    departmentId,
    start: startDate,
    end: endDate,
  };

  return AxiosInstance.get('livechat/analytics/dashboards/charts/chats-per-agent', {
    params,
  });
};
