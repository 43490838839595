/* eslint-disable no-underscore-dangle */
import React from 'react';
import { t } from 'i18next';
import { Typography, ListItem, Box } from '@mui/material';
import { Container, Header, SelectsDiv, SelectWrapper, CardsDiv, TableHeaderDiv } from './style';
import TimeSelector from './components/TimeSelector';
import GenericAutocomplete from '../../../components/Autocomplete';
import CustomSnackBar from '../../../components/Snackbar';
import CustomTable from '../../../components/Table';
import useServiceDashboard from './useServiceDashboard';
import Card from './components/Card';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import { IConversationStatus } from './getConversationsStats';

const ServiceDashboard = () => {
  const {
    teams,
    agentsTable,
    tableHeader,
    conversationStats,
    selectedTeam,
    setSelectedTeam,
    totalOnline,
    formatedDate,
    formatedHour,
    loadingDataError,
    setLoadingDataError,
    timeUpdateSelected,
    setTimeUpdateSelected,
    loading,
  } = useServiceDashboard();

  const handleSnackbar = () => {
    setLoadingDataError((old: boolean) => !old);
  };

  return (
    <Container data-testid="dashboardAnalyticsContainer">
      <Header>
        <Typography variant="h2">{t('liveData')}</Typography>
        <div className="timeSelector">
          <TimeSelector timeUpdateSelected={timeUpdateSelected} setTimeUpdateSelected={setTimeUpdateSelected} />
        </div>
      </Header>
      <Typography variant="subtitle1">
        {`${t('dataFromToday')} (${formatedDate} ${t('hourUpUntil')} ${formatedHour})`}
      </Typography>
      <SelectsDiv>
        <SelectWrapper>
          <GenericAutocomplete
            disableCloseOnSelect
            hideSearchIcon
            placeholder="Teams"
            renderOption={(props, option) => (
              <ListItem
                sx={{
                  height: '36px',
                  gap: '6px',
                  padding: '8px 12px',
                }}
                {...props}
              >
                <Typography variant="caption1">{option.name}</Typography>
              </ListItem>
            )}
            handleChange={(value) => {
              setSelectedTeam(value ? value._id : '');
            }}
            limitTagsNumber={1}
            multiple={false}
            options={teams}
            defaultValue={selectedTeam}
          />
        </SelectWrapper>
      </SelectsDiv>
      {loading ? (
        <Box alignSelf="center">
          <Loading />
        </Box>
      ) : (
        <>
          <Typography style={{ marginTop: 32, marginBottom: 8 }} variant="h3">
            {t('conversations')}
          </Typography>
          <CardsDiv>
            {Object.keys(conversationStats).map((key) => {
              const value = conversationStats[key as keyof IConversationStatus];
              if (value) {
                return <Card key={key} data={value} title={key} />;
              }
              return null;
            })}
          </CardsDiv>
          <TableHeaderDiv>
            <Typography variant="h3" style={{ marginBottom: 8 }}>
              {t('agents')}
            </Typography>
            <Typography style={{ marginLeft: 8 }} variant="subtitle1">
              ({totalOnline} {t('onlineAgents')})
            </Typography>
          </TableHeaderDiv>
          <CustomTable
            order="asc"
            orderBy="status"
            rows={agentsTable}
            headItems={tableHeader}
            page={1}
            handleClickItem={() => {}}
          />
        </>
      )}

      {loadingDataError && (
        <CustomSnackBar
          open={loadingDataError}
          message={t('dashboadError')}
          handleClose={handleSnackbar}
          type="error"
        />
      )}
    </Container>
  );
};
export default ServiceDashboard;
