import React from 'react';
import { Avatar } from '@mui/material';

export const renderAvatar = (baseUrl: string, username: string, avatarETag?: string, name?: string) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Avatar
      sx={{
        width: '24px',
        height: '24px',
        marginRight: 1,
      }}
      src={`${baseUrl}/avatar/${username}?etag=${avatarETag}`}
    />
    {name}
  </div>
);
