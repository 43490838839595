import React, { useCallback, useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getTreatments } from '@splitsoftware/splitio-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import { Alert, AlertTitle } from '@engyalo/design-system';
import { Box } from '@mui/material';
import AuthContext from 'contexts/auth/context';
import AlertsContext from 'contexts/alerts/context';
import useEnvironment from 'hooks/useEnvironments';
import { useFeatureFlag, flag } from 'hooks/useFeatureFlag';
import UploadStatus from 'components/UploadStatus';
import { logWarn } from 'services/Logger';
import { setUser } from '../../redux/reducers/configReducer';
import SplitNames from '../../constants/featureFlags';
import { scriptUrl } from './constants';

const isScriptAlreadyInjected = () => document.querySelectorAll(`[src="${scriptUrl}"]`).length > 0;

const AppWrapper: React.FC = ({ children }) => {
  const { getInstance } = useEnvironment();
  const { currentUserInfo, isAuthenticated } = useContext(AuthContext);
  const [isScriptInjected, setIsScriptInjected] = useState(false);
  const { alerts, removeAlert } = useContext(AlertsContext);
  const isUserConsentScriptEnabled = useFeatureFlag(flag.USER_CONSENT_SCRIPT);
  const dispatch = useDispatch();
  const instance = getInstance();

  useEffect(() => {
    if (isAuthenticated() && currentUserInfo) {
      const { email, username } = currentUserInfo;
      const contextAttributes = {
        mail: email,
        username,
        instance,
      };
      try {
        dispatch(
          getTreatments({
            splitNames: Object.values(SplitNames),
            attributes: contextAttributes,
            key: contextAttributes.mail,
          }) as never
        );
      } catch (error) {
        logWarn('Error getting treatments', error);
      }

      dispatch(setUser(currentUserInfo));
    }
  }, [currentUserInfo, dispatch, instance, isAuthenticated]);

  const addConcentScript = useCallback(() => {
    if (!isScriptAlreadyInjected()) {
      const script = document.createElement('script');
      script.setAttribute('data-cfasync', 'false');
      script.src = scriptUrl;
      document.head.appendChild(script);
      setIsScriptInjected(true);
    }
  }, [setIsScriptInjected]);

  useEffect(() => {
    if (isUserConsentScriptEnabled && !isScriptInjected) {
      addConcentScript();
    }
  }, [isScriptInjected, isUserConsentScriptEnabled, addConcentScript]);

  return (
    <>
      {alerts && (
        <Box
          sx={{
            position: 'fixed',
            width: '100vw',
            top: '8px',
            zIndex: 1500,
          }}
        >
          {alerts.map((alert) => (
            <Alert
              key={alert.id}
              sx={{ float: 'right', clear: 'right', marginBottom: '4px' }}
              severity={alert.severity}
              onClose={() => removeAlert(alert.id)}
            >
              <AlertTitle>{`${alert.message}${alert.occurrences > 1 ? ` (x${alert.occurrences})` : ''}`}</AlertTitle>
            </Alert>
          ))}
        </Box>
      )}
      <UploadStatus />
      {children}
    </>
  );
};

export default AppWrapper;
