import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@engyalo/design-system';
import { Stack, Typography } from '@mui/material';
import useEnvironment from 'hooks/useEnvironments';
import { CONVERSATION_EXPLORER_DAYS_RANGE, MILLIS_IN_ONE_DAY } from 'constants/';

const makeDateRepresentation = (date: Date): string => date.toISOString().split('T').shift()!;

export enum CONVERSATION_EXPLORER_SEARCH_PARAMETERS {
  MENU = 'menu',
  PHONE = 'phone',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
}

export const ConversationExplorerAd = ({ chatDate, phone }: { chatDate: string; phone: string }) => {
  const { t } = useTranslation();
  const { getBasePath } = useEnvironment();
  const basePath = useMemo(getBasePath, [getBasePath]);
  const params = useMemo(() => {
    const startDate = new Date(chatDate);
    const endDate = new Date(startDate.getTime() + CONVERSATION_EXPLORER_DAYS_RANGE * MILLIS_IN_ONE_DAY);
    const searchParams = new URLSearchParams();
    searchParams.append(CONVERSATION_EXPLORER_SEARCH_PARAMETERS.MENU, 'conversation-explorer');
    searchParams.append(CONVERSATION_EXPLORER_SEARCH_PARAMETERS.PHONE, phone);
    searchParams.append(CONVERSATION_EXPLORER_SEARCH_PARAMETERS.START_DATE, makeDateRepresentation(startDate));
    searchParams.append(CONVERSATION_EXPLORER_SEARCH_PARAMETERS.END_DATE, makeDateRepresentation(endDate));
    return searchParams;
  }, [chatDate, phone]);

  return (
    <Stack alignItems="center" justifyContent="center" minHeight="100%" padding={4}>
      <Typography variant="h3">{`${t('salesdesk-conversation-explorer.ad')} `}</Typography>
      <Link variant="h3" href={`${basePath}?${params.toString()}`}>
        {t('salesdesk-conversation-explorer.cta')}
      </Link>
    </Stack>
  );
};
