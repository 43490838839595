import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { setWorkHours } from 'redux/reducers/businessHoursReducer';
import { getValidTimedDate } from '../utils';
import { HourRange } from '../types';
import { UpdateSelectedDayTimeProps } from './types';
import { DayLabel } from './styles';

const TimePickerComponent = () => {
  const { t } = useTranslation();
  const { workHours } = useAppSelector((state) => state.businessHours.selectedSchedule);
  const dispatch = useAppDispatch();

  const updateSelectedDayTime = ({ value, type, index }: UpdateSelectedDayTimeProps) => {
    if (!value || Number.isNaN(value.getTime())) {
      return;
    }

    const time = format(value, 'HH:mm');
    const newDays = [...workHours];

    let targetDay = newDays[index];
    targetDay = { ...targetDay, [type]: time };
    newDays[index] = targetDay;
    dispatch(setWorkHours(newDays));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {workHours.map((day, index) => (
        <Box display="flex" gap={2} alignItems="center">
          <DayLabel variant="subtitle2" data-testid="day-label">
            {t(`business-hours.days.${day.dayOfWeek}`)}
          </DayLabel>
          <TimePicker
            key={`${day}-start`}
            data-testid="time-picker-start"
            ampm={false}
            value={getValidTimedDate(day.opens)}
            onChange={(newValue) => updateSelectedDayTime({ value: newValue, type: HourRange.START, index })}
            renderInput={(props) => (
              <TextField
                {...props}
                data-testid="time-picker-start"
                inputProps={{
                  ...props.inputProps,
                  placeholder: t('business-hours.timePicker.start'),
                }}
              />
            )}
          />
          <TimePicker
            key={`${day}-end`}
            value={getValidTimedDate(day.closes)}
            ampm={false}
            onChange={(newValue) => updateSelectedDayTime({ value: newValue, type: HourRange.END, index })}
            renderInput={(props) => (
              <TextField
                {...props}
                data-testid="time-picker-end"
                inputProps={{
                  ...props.inputProps,
                  placeholder: t('business-hours.timePicker.end'),
                }}
              />
            )}
          />
        </Box>
      ))}
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
