import { Box, Typography } from '@mui/material';

interface IFallbackMessageProps {
  message: string;
}

const FallbackMessage: React.FC<IFallbackMessageProps> = ({ message }) => (
  <Box height="100%" padding="40px">
    <Typography variant="h3" color="neutral.var60" textAlign="center">
      <i className="fa fa-solid fa-search fa-xl" style={{ marginRight: '0.5rem' }} />
      {message}
    </Typography>
  </Box>
);

export default FallbackMessage;
