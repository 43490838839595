/* eslint-disable react-hooks/exhaustive-deps */
// Disabling exhaustive-deps because the useEffects are dependent on the state of the component
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { getVisitorByTerm } from 'services/getVisitorByTerm';
import { ModelsConversationsEntry, OutgoingMessageChatMessage } from '@engyalo/salesdesk-bff-ts-client';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@engyalo/design-system';
import { Autocomplete, Typography, Skeleton, Box, MenuItem } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatDate, getDateFnsLocale } from 'helpers/formatterDates';
import { messageHistoryToTranscript } from 'helpers/conversationHelpers';
import { maskPhoneNumber } from 'contexts/drawer/provider';
import debounce from 'lodash.debounce';
import { GetBotsByClientResponse, getBotsByClient } from 'services/getBotsByClient';
import { getConversations, getLatestConversations } from 'services/conversationExplorer';
import { searchUser } from 'services/contactSearch';
import { logError } from 'services/Logger';
import Conversation from '../../../components/Conversation';
import { CONVERSATION_EXPLORER_SEARCH_PARAMETERS } from '../../../components/ConversationExplorerAd/ConversationExplorerAd';
import { groupMessagesByDateStamp } from './utils/groupMessagesByDateStamp';
import { Container, InputArea, TextField } from './ConversationExplorer.styled';
import FallbackMessage from './FallbackMessage';
import { isValidPhone, isWithinRange } from './utils';
import { FetchContactInfoParams } from './ConversationExplorer.types';

interface SingleContactItem {
  name: string;
  phoneNumber: string;
}

type Dates = { startDate: Date; endDate: Date };

type ValidationFieldTypes = {
  phoneNumber: string;
  endDate: Dates;
  startDate: Dates;
};

type ErrorTranslationKey = string;

type Validations = {
  [K in keyof ValidationFieldTypes]: Array<[(value: ValidationFieldTypes[K]) => boolean, ErrorTranslationKey]>;
};

const inputErrorKeyPrefix = 'conversation-explorer.inputError';

const inputValidations: Validations = {
  phoneNumber: [[(value) => !!value.length && !isValidPhone(value), 'phone.invalid']],
  endDate: [
    [(dates) => !isValid(dates.endDate), 'dates.invalid'],
    [(dates) => !isWithinRange(startOfDay(dates.startDate), endOfDay(dates.endDate)), 'dates.endDate.range'],
  ],
  startDate: [[(dates) => !isValid(dates.startDate), 'dates.invalid']],
};

function areDatesEqual(date1: Date, date2: Date) {
  return date1.getTime() === date2.getTime();
}

export interface IConversationExplorerProps {
  phone?: string;
  startDate?: Date;
  endDate?: Date;
}

const ConversationExplorer = ({
  phone,
  startDate: initialStartDate,
  endDate: initialEndDate,
}: IConversationExplorerProps) => {
  const { t, i18n } = useTranslation();
  const [contacts, setContacts] = useState<SingleContactItem[]>([]);
  const [botList, setBotList] = useState<GetBotsByClientResponse[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [currentClient, setCurrentClient] = useState<string>('');
  const [newRecentNumbers, setNewRecentNumbers] = useState<ModelsConversationsEntry[]>([]);
  const [fetchingNumbers, setFetchingNumbers] = useState<boolean>(false);
  const [botId, setBotId] = useState<string>('');
  const [endDate, setEndDate] = useState<Date>(startOfDay(new Date()));
  const [startDate, setStartDate] = useState<Date>(endOfDay(new Date()));
  const [groupedMessagesByDate, setGroupedMessagesByDate] = useState<Record<string, OutgoingMessageChatMessage[]>>({});
  const [totalConversations, setTotalConversations] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string | null>();
  const [inputErrors, setInputErrors] = useState<Record<string, string>>({});
  const [clientInfo, setClientInfo] = useState<SingleContactItem | null>(null);

  const adapterLocale = getDateFnsLocale(i18n.language);

  const [transcriptBlobURL, setTranscriptBlobURL] = useState<string>('');
  const [transcriptFileName, setTranscriptFileName] = useState<string>('');

  useEffect(() => {
    if (groupedMessagesByDate && Object.keys(groupedMessagesByDate).length) {
      const messageHistoryBlob = new Blob([messageHistoryToTranscript(groupedMessagesByDate)], { type: 'text/plain' });
      setTranscriptBlobURL(URL.createObjectURL(messageHistoryBlob));
      setTranscriptFileName(`transcript-${currentClient}.txt`);
    } else {
      setTranscriptBlobURL('');
    }
  }, [groupedMessagesByDate, currentClient]);

  const fetchContactInfo = async ({ client }: FetchContactInfoParams) => {
    const {
      data: { contact },
    } = await searchUser('phone', client);
    if (!contact) {
      throw new Error(`No contact for ${client} found`);
    }
    return { name: contact.name, phoneNumber: client };
  };

  const fetchConversations = async () => {
    setIsLoading(true);
    try {
      const {
        data: { messages = [], pagination },
      } = await getConversations({
        botId,
        phoneNumber,
        fromDate: startDate,
        toDate: endDate,
      });
      const groupMessages = groupMessagesByDateStamp(messages);
      setGroupedMessagesByDate(groupMessages);
      setTotalConversations(pagination?.totalCount);
      setCurrentClient(phoneNumber);
      const contactInfo = await fetchContactInfo({ client: phoneNumber });
      setClientInfo(contactInfo);
    } catch (error) {
      logError('Error while fetching conversations', error);
      setMessageError((error as Error).message);
    }
    setIsLoading(false);
  };

  const getBots = async () => {
    const { data } = await getBotsByClient();
    if (data.length === 1) {
      setBotId(data[0].botID);
    }
    setBotList(data);
  };

  const fetchRecentNumbers = async () => {
    setFetchingNumbers(true);
    const {
      data: { latestChats },
    } = await getLatestConversations();
    setFetchingNumbers(false);
    setNewRecentNumbers(latestChats || []);
  };

  useEffect(() => {
    const canFetchConversations =
      isValidPhone(phoneNumber) &&
      startDate &&
      endDate &&
      isWithinRange(startOfDay(startDate), endOfDay(endDate)) &&
      botId;
    if (canFetchConversations) {
      fetchConversations();
    }
  }, [botId, endDate, phoneNumber, startDate]);

  useEffect(() => {
    getBots();
    const currentUrl = new URL(window.location.toString());
    if (currentUrl.searchParams.size) {
      Object.values(CONVERSATION_EXPLORER_SEARCH_PARAMETERS).forEach((searchParam) =>
        currentUrl.searchParams.delete(searchParam)
      );

      window.history.pushState({}, '', currentUrl);
    }
  }, []);

  useEffect(() => {
    fetchRecentNumbers();
  }, [botId]);

  const validateInput = <K extends keyof ValidationFieldTypes>(inputName: K, value: ValidationFieldTypes[K]) => {
    const conditions = inputValidations[inputName];
    const [_, translationKey] = conditions.find(([validate]) => validate(value)) || [];
    const errorKey = translationKey ? `${inputErrorKeyPrefix}.${translationKey}` : '';
    setInputErrors((prev) => ({ ...prev, [inputName]: errorKey }));
  };

  const handleShouldDisableEndDate = (date: Date | null) => {
    if (date && startDate) {
      return !isWithinRange(startOfDay(startDate), endOfDay(date));
    }

    return false;
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      validateInput('startDate', { startDate: date, endDate });
      validateInput('endDate', { startDate: date, endDate });
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      validateInput('endDate', { startDate, endDate: date });
      setEndDate(date);
    }
  };

  const getContactOptions = (contactsList: SingleContactItem[]) =>
    contactsList.map((contact) => ({
      id: contact.phoneNumber,
      label: `${contact.name} - ${contact.phoneNumber}`,
      phone: contact.phoneNumber,
    }));

  const handleSearchContact = async () => {
    if (!phoneNumber) {
      setContacts([]);
      return;
    }

    const {
      data: { visitors },
    } = await getVisitorByTerm({
      term: `${phoneNumber}`,
      count: 25,
      offset: 0,
      sort: 'name',
      sortValue: 1,
    });

    const contactsFiltered: SingleContactItem[] = visitors.map((visitor) => ({
      phoneNumber: visitor.phone ? visitor.phone[0].phoneNumber : '',
      name: visitor.name,
    }));
    setContacts(contactsFiltered);
  };

  const dispatchPhoneNumber = ({ target: { value } }: { target: { value: string } }) => {
    validateInput('phoneNumber', value);
    setPhoneNumber(value);
  };

  const getAutocompleteOptions = (numbers: Array<ModelsConversationsEntry>) =>
    numbers.map((number) => ({
      id: number.botId,
      label: number.phone,
      botId: number.botId,
      phone: number.phone,
    }));

  const handlePhoneNumberChange = debounce(dispatchPhoneNumber, 800);

  useEffect(() => {
    handleSearchContact();
  }, [phoneNumber]);

  useEffect(() => {
    if (phone && phone !== phoneNumber) {
      dispatchPhoneNumber({ target: { value: phone } });
    }

    if (initialStartDate && !areDatesEqual(initialStartDate, startDate)) {
      handleStartDateChange(initialStartDate);
    }

    if (initialEndDate && !areDatesEqual(initialEndDate, endDate)) {
      handleEndDateChange(initialEndDate);
    }
  }, [
    dispatchPhoneNumber,
    endDate,
    handleEndDateChange,
    handleStartDateChange,
    initialEndDate,
    initialStartDate,
    phone,
    phoneNumber,
    startDate,
  ]);

  // TODO: create a DangeRangePicker component to encapsulate the date pickers logic
  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
        <Typography variant="h2" color="common.black">
          {t('salesdesk-conversation-explorer')}
        </Typography>
        <Typography>{t('salesdesk-conversation-explorer.description')}</Typography>
        <InputArea>
          <Autocomplete
            freeSolo
            value={phoneNumber}
            getOptionDisabled={() => fetchingNumbers}
            options={[...getAutocompleteOptions(newRecentNumbers), ...getContactOptions(contacts)]}
            loading={fetchingNumbers}
            loadingText={t('salesdesk-conversation-explorer.searching.numbers')}
            onChange={(_event, value) => {
              if (typeof value === 'object' && value !== null && value.phone) {
                validateInput('phoneNumber', value.phone);
                setPhoneNumber(value.phone);
              }

              if (value === null) {
                validateInput('phoneNumber', '');
                setPhoneNumber('');
              }
            }}
            sx={{ flexGrow: 3, flexShrink: 0, minWidth: '30rem', '&& .MuiAutocomplete-input': { padding: '1rem' } }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!inputErrors.phoneNumber}
                label={t('phone')}
                placeholder={t('phone')}
                helperText={t(inputErrors.phoneNumber)}
                onChange={handlePhoneNumberChange}
              />
            )}
          />
          <DatePicker
            disableFuture
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!inputErrors.startDate}
                sx={{
                  flexGrow: 1,
                }}
                label={t('salesdesk-conversation-explorer.startDate.label')}
                helperText={t(inputErrors.startDate)}
              />
            )}
          />
          <DatePicker
            disableFuture
            shouldDisableDate={handleShouldDisableEndDate}
            data-testid="explorer-end-date"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!inputErrors.endDate}
                sx={{
                  flexGrow: 1,
                }}
                label={t('salesdesk-conversation-explorer.endDate.label')}
                helperText={t(inputErrors.endDate)}
              />
            )}
          />

          {botList.length > 1 ? (
            <TextField
              sx={{
                flexGrow: 1,
                minWidth: '20rem',
              }}
              select
              label={t('salesdesk-conversation-explorer.botId.label')}
              placeholder={t('salesdesk-conversation-explorer.botId.placeholder')}
              value={botId}
              onChange={(e) => setBotId(e.target.value)}
            >
              {botList.map((option: GetBotsByClientResponse) => (
                <MenuItem key={option.botID} value={option.botID}>
                  {option.botID}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </InputArea>
        {!isLoading && !isValidPhone(phoneNumber) && !messageError ? (
          <FallbackMessage message={t('salesdesk-conversation-explorer.empty.params')} />
        ) : null}
        {isLoading ? (
          <Box sx={{ height: '100%', padding: '2rem' }}>
            <Skeleton width="100%" />
            <Skeleton>
              <Avatar variant="icon" />
            </Skeleton>
            <Skeleton width="40rem" height="4rem" />
          </Box>
        ) : null}
        {!isLoading && totalConversations && isValidPhone(phoneNumber) ? (
          <Conversation
            headerName={clientInfo?.name || currentClient}
            messagesHistory={groupedMessagesByDate}
            sx={{ flexGrow: 1 }}
            menuItems={[
              {
                key: 'download-transcript',
                disabled: !transcriptBlobURL,
                component: 'a',
                href: transcriptBlobURL,
                download: transcriptFileName,
                icon: 'fa-solid fa-download',
                text: t('downloadTranscription'),
              },
            ]}
          />
        ) : null}
        {!isLoading && isValidPhone(phoneNumber) && (totalConversations === 0 || messageError) ? (
          <FallbackMessage
            message={t('salesdesk-conversation-explorer.empty.result', {
              // TODO: improve phone number masking
              phoneNumber: maskPhoneNumber(phoneNumber),
              startDate: formatDate(startDate, i18n.language),
              endDate: formatDate(endDate, i18n.language),
            })}
          />
        ) : null}
      </LocalizationProvider>
    </Container>
  );
};

export default ConversationExplorer;
