import React, { useEffect, useState } from 'react';
import { Autocomplete, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AutoCompleteInputStyle, AutoCompleteStyle } from 'views/home/Conversation/style';
import { TextField } from '@engyalo/design-system';
import { ArrowIcon } from 'views/ServiceManagement/BusinessHours/TimezoneSelector';
import { IAutocomplete } from './types';
import { CreateItemContainer, SelectItemsText } from './style';

const GenericAutocomplete: React.FC<IAutocomplete> = ({
  options,
  limitTagsNumber,
  multiple,
  handleChange,
  renderOption,
  defaultValue,
  placeholder,
  disabled,
  error,
  disableCloseOnSelect,
  labelCountItemsSelecteds,
  renderCountItemsSelecteds,
  createItem,
  onCreateItem,
}: IAutocomplete) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<any | Array<any> | null>(multiple ? [] : null);

  useEffect(() => {
    if (defaultValue) {
      let option = null;
      if (multiple) {
        option = options.filter((item) => {
          if (Array.isArray(defaultValue)) {
            return defaultValue.includes(item._id);
          }
          return item._id === defaultValue;
        });
      } else {
        option = options.find((item) => item._id === defaultValue);
      }

      setValue(option);
    }
  }, [defaultValue, options]);

  const handleElement = (childrens: any) => {
    const element = childrens.find((item: any) => {
      if (item !== undefined) {
        return item;
      }
    });
    if (element && element.props.as === 'ul') {
      const newElement = React.cloneElement(element);
      return newElement;
    }
  };

  const CustomPaper = (props: any) => {
    const children = handleElement(props.children);
    return (
      <Paper role="paperItems" {...props}>
        {children}
        {createItem && (
          <CreateItemContainer
            role="createItem"
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (onCreateItem) {
                onCreateItem(event);
              }
            }}
          >
            {t('createNewUnit')}
          </CreateItemContainer>
        )}
      </Paper>
    );
  };

  return (
    <Autocomplete
      disabled={disabled}
      openOnFocus
      popupIcon={<ArrowIcon />}
      disableCloseOnSelect={!disableCloseOnSelect}
      sx={{
        ...AutoCompleteStyle,
      }}
      options={options}
      getOptionLabel={(option) => option.name || ''}
      limitTags={limitTagsNumber ?? 2}
      multiple={multiple}
      value={value}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
      onChange={(_event: any, newValue: any | Array<any>, _, detail) => {
        if (newValue !== value) {
          let newValueOptions;
          const hasAllOption = options.find((item) => item._id === 'allOptions');
          if (hasAllOption) {
            if (detail?.option && detail?.option._id === 'allOptions') {
              if (multiple) {
                const include = value.find((item: any) => item._id === detail.option._id);
                if (!include) {
                  setValue(options);
                  newValueOptions = options;
                } else {
                  setValue([]);
                  newValueOptions = [];
                }
              } else {
                setValue(newValue);
                newValueOptions = newValue;
              }
            } else {
              const allOption = Array.isArray(value) ? value.find((item: any) => item._id === 'allOptions') : value;
              if (options?.length - 1 === newValue?.length && !allOption) {
                setValue(options);
                newValueOptions = options;
              } else if (Array.isArray(newValue)) {
                const filterOptions = newValue.filter((item: any) => item._id !== 'allOptions');
                setValue(filterOptions);
                newValueOptions = filterOptions;
              } else {
                setValue(newValue);
                newValueOptions = newValue;
              }
            }
          } else {
            setValue(newValue);
            newValueOptions = newValue;
          }
          handleChange(newValueOptions);
        }
      }}
      renderOption={(props, option, { selected }) => renderOption(props, option, { selected })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={!value || value?.length === 0 ? placeholder : ''}
          sx={AutoCompleteInputStyle}
          error={error}
        />
      )}
      renderTags={(tagValue) => {
        const values = tagValue.map((item) => item.name).join(',');
        if (renderCountItemsSelecteds) {
          return (
            <SelectItemsText variant="body1">{`${tagValue.length} ${labelCountItemsSelecteds} ${t(
              'selected'
            )}`}</SelectItemsText>
          );
        }
        return (
          <SelectItemsText variant="body1" role="selectValueText">
            {values}
          </SelectItemsText>
        );
      }}
      PaperComponent={CustomPaper}
      data-testid="customAutocompleteTestId"
    />
  );
};

export default GenericAutocomplete;
