import { ConversationExplorerApi } from '@engyalo/salesdesk-bff-ts-client';
import { convertDateToSeparator } from 'helpers/formatterDates';
import AxiosInstanceBFF from 'services/AxiosInstanceBFF';

export type GetConversationParams = {
  botId: string;
  phoneNumber: string;
  fromDate: Date;
  toDate: Date;
};

const conversationApi = new ConversationExplorerApi(undefined, undefined, AxiosInstanceBFF);

export const getLatestConversations = () => conversationApi.getLatestConversations();

export const getConversations = ({ botId, phoneNumber, fromDate, toDate }: GetConversationParams) => {
  const formattedFromDate = convertDateToSeparator(fromDate);
  const formattedToDate = convertDateToSeparator(toDate);

  return conversationApi.getConversationByBotIdAndPhone(phoneNumber, botId, formattedFromDate, formattedToDate);
};
