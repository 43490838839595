/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { Autocomplete, Checkbox, Paper, InputAdornment, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { CustomTextField, InputItem, IconEditTag, OptionItem, ListItemCustom } from './style';
import { TagsType } from '../../../types/tags';
import { tagsColor } from '../../../helpers/tagsColors';
import hasDisabledSuffix from '../../../helpers/hasDisabledSuffix';
import CreateTagModal from '../CreateTagModal';
import useEditTags from './useEditTags';
import { CustomChip } from '../style';
import { responseCreateTagModal } from '../CreateTagModal/types';
import shadows from '../../../theme/shadows';

export interface IEditTags {
  tags?: TagsType[];
  currentTags?: string;
  handleChange: (value: string) => void;
  limitTagsNumber?: number;
  editTag: boolean;
  createTag: boolean;
  placeholder?: string;
  type: 'room' | 'visitor';
  open?: boolean;
  sizedTag?: string;
  includeDisabled?: boolean;
}

const EditTags: React.FC<IEditTags> = (props) => {
  const theme = useTheme();
  const {
    currentTags,
    handleChange,
    limitTagsNumber,
    editTag,
    createTag,
    placeholder,
    type,
    open,
    sizedTag,
    includeDisabled,
  } = props;

  const {
    handleFocus,
    setOpenModal,
    isFocus,
    value,
    setValue,
    setCurrentTag,
    openModal,
    currentTag,
    handleCloseCreateModal,
    listTags,
    loadTags,
  } = useEditTags({
    currentTags,
    handleChange,
    type,
    open,
    includeDisabled,
  });

  const { t } = useTranslation();

  const scroll = (event: React.UIEvent<HTMLElement>) => {
    const isBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight;

    if (isBottom) {
      loadTags();
    }
  };

  const handleElement = (childrens: any) => {
    const element = childrens.find((item: any) => {
      if (item !== undefined) {
        return item;
      }
    });
    if (element && element.props.as === 'ul') {
      const newElement = React.cloneElement(element, {
        onScroll: (event) => scroll(event),
      });
      return newElement;
    }
    return element;
  };

  const CustomPaper = (props: any) => {
    const children = handleElement(props.children);
    return (
      <Paper
        sx={{
          border: `1px solid ${theme.palette.primary.main}`,
          '& ul': {
            height: '200px',
            overflowY: 'scroll',
          },
          maxHeight: '275px',
          overflowY: 'hidden',
        }}
        {...props}
      >
        {children}
        {createTag && (
          <div
            style={{
              padding: '16px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              data-testid="btnCreateTagEditTags"
              id="newTag"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                handleFocus();
                setOpenModal(true);
              }}
              fullWidth
              variant="contained"
              color="neutral2"
              disableElevation
              startIcon={<i className="fa-regular fa-plus" />}
            >
              {t('newTag')}
            </Button>
          </div>
        )}
      </Paper>
    );
  };

  const listTagsSortedBySelected = useMemo(() => {
    if (currentTags?.length) {
      return listTags.sort((a, b) => currentTags?.split(';').findIndex((tag) => tag === b.name));
    }
    return listTags;
  }, [currentTags, listTags]);

  return (
    <>
      <Autocomplete
        open={isFocus}
        options={listTagsSortedBySelected}
        limitTags={limitTagsNumber ?? 2}
        multiple
        disableCloseOnSelect
        value={value}
        onChange={(event: React.SyntheticEvent, newValue: TagsType[]) => {
          setValue(newValue);
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        getOptionLabel={(option) => option.name}
        sx={{
          background: `${theme.palette.neutral.var10}`,
          borderRadius: '4px',
          width: '100%',
          border: isFocus ? `1px solid ${theme.palette.neutral.var30}` : 'none',
          '& input': {
            minWidth: '0px !important',
          },
          ':hover': {
            border: `1px solid ${theme.palette.neutral.var40}`,
            boxShadow: `${shadows.shadowBlack4}`,
          },
          ':active': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: `${shadows.shadowPurple8}`,
          },
        }}
        renderOption={(props, option, { selected }) => (
          <ListItemCustom {...props}>
            <OptionItem>
              <div>
                <Checkbox disableRipple disableTouchRipple style={{ marginRight: 8 }} checked={selected} />
                <CustomChip
                  width={sizedTag}
                  size="small"
                  label={option.name}
                  customcolor={tagsColor(option.name)}
                  disabled={hasDisabledSuffix(option.description)}
                  icon={hasDisabledSuffix(option.description) ? <Icon className="fa-solid fa-ban" /> : undefined}
                />
              </div>

              {editTag && (
                <IconEditTag
                  data-testid="editTagBtn"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    setOpenModal(true);
                    setCurrentTag(option);
                  }}
                >
                  <i className="fa-regular fa-pencil fa-xs" />
                </IconEditTag>
              )}
            </OptionItem>
          </ListItemCustom>
        )}
        PaperComponent={CustomPaper}
        renderTags={(tagValue) =>
          tagValue.map((option, index) => (
            <CustomChip size="small" key={index} label={option.name} customcolor={tagsColor(option.name)} />
          ))
        }
        renderInput={(params) => {
          const { InputProps, ...rest } = params;
          return (
            <InputItem ref={InputProps.ref}>
              <i
                className="fa-regular fa-magnifying-glass fa-lg"
                style={{
                  color: theme.palette.neutral.var70,
                  opacity: '65%',
                  marginRight: '12px',
                }}
              />
              <CustomTextField
                data-testid="inputTextEditTags"
                placeholder={!value?.length ? placeholder : ''}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  const { relatedTarget } = event;
                  if (
                    (isFocus && (!relatedTarget || (relatedTarget && relatedTarget.id === ''))) ||
                    (isFocus && relatedTarget && relatedTarget.id && relatedTarget.id !== 'newTag')
                  ) {
                    handleFocus();
                  }
                }}
                onClick={() => handleFocus()}
                variant="standard"
                {...rest}
                InputProps={{
                  ref: InputProps.ref,
                  startAdornment: InputProps.startAdornment,
                  className: InputProps.className,

                  endAdornment: (
                    <InputAdornment position="end">
                      {isFocus ? (
                        <i
                          className="fa-regular fa-chevron-up fa-sm"
                          tabIndex={-1}
                          style={{ color: theme.palette.neutral.var70 }}
                        />
                      ) : (
                        <i
                          className="fa-regular fa-chevron-down fa-sm"
                          tabIndex={-1}
                          style={{ color: theme.palette.neutral.var70 }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </InputItem>
          );
        }}
        onTouchStart={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
        data-testid="autocompleteTags"
      />
      {openModal && (
        <CreateTagModal
          typeDescription={type}
          tag={currentTag}
          open={openModal}
          handleCloseModal={(confirm: boolean, tagValue?: responseCreateTagModal) =>
            handleCloseCreateModal(confirm, tagValue)
          }
        />
      )}
    </>
  );
};

export default EditTags;
