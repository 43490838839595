import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutral2.lighter};
  border-left: 1px solid ${({ theme }) => theme.palette.neutral2.dark};
  height: 100%;
  padding: 24px 48px;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;

  .timeSelector {
    display: flex;
    align-items: center;
  }
`;

export const SelectsDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

export const SelectWrapper = styled('div')`
  width: 32%;
  border: 1px solid ${({ theme }) => theme.palette.neutral.var30};
  border-radius: 4px;
`;

export const CardsDiv = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  row-gap: 1.6rem;
  column-gap: 1.6rem;
`;

export const TableHeaderDiv = styled('div')`
  display: flex;
  margin-top: 32px;
`;
