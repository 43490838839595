import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { UserInfo, UserStatusFilterState, UserStatusFilterStateValues } from './types';

interface IGetUsersList {
  users: UserInfo[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
}

export const getUsersList = async (
  search: string,
  active: UserStatusFilterStateValues = UserStatusFilterState.All
): Promise<AxiosResponse<IGetUsersList>> => {
  const query: Record<string, unknown> = {
    $or: [
      { 'emails.address': { $regex: search, $options: 'i' } },
      { username: { $regex: search, $options: 'i' } },
      { name: { $regex: search, $options: 'i' } },
    ],
  };

  if (active !== UserStatusFilterState.All) {
    query.active = active;
  }

  return AxiosInstance.get('users.list', {
    params: {
      fields: {
        name: 1,
        username: 1,
        emails: 1,
        roles: 1,
        status: 1,
        avatarETag: 1,
        active: 1,
      },
      query,
      sort: { name: 1, usernames: 1 },
    },
  });
};
