import { ListItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardPropHelper } from '@engyalo/design-system';

interface IItem {
  isSelected: boolean;
  isCurrentChat: boolean;
}

export const Item = styled(ListItem, {
  shouldForwardProp: shouldForwardPropHelper,
})<IItem>`
  background-color: ${({ isSelected, isCurrentChat, ...props }) =>
    isSelected || isCurrentChat ? props.theme.palette.neutral.var20 : props.theme.palette.common.white};
  padding: 8px 8px 8px 16px;
  cursor: pointer;
  &:hover {
    background: ${({ isSelected, isCurrentChat, ...props }) =>
      isSelected || isCurrentChat ? props.theme.palette.neutral.var20 : props.theme.palette.neutral.var10};
  }
`;

export const TitleContainer = styled(Stack)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const ItemTitleAllChatsContainer = styled(Stack)`
  display: flex;
  flex-direction: row;
`;

export const TitleSeparator = styled('p')`
  color: ${(props) => props.theme.palette.neutral.var30};
  margin-inline: 6px;
`;

export const ItemTime = styled(Typography)`
  white-space: nowrap;
  margin-left: 2px;
`;

export const ItemBody = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
`;

export const OptionsContainerChat = styled('span')`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin-left: 0.4rem;
`;

export const UnReadBadge = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.6rem;
  height: 1.6rem;
  max-width: 3.2rem;
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 1rem;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 1rem;
  font-weight: 400;
`;

interface IOptions {
  islongclick: number;
}
export const Options = styled('span')<IOptions>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ islongclick }) => (islongclick === 1 ? '1.6rem' : '0')};
  height: 1.6rem;
  color: ${(props) => props.theme.palette.neutral.var90};
  visibility: ${({ islongclick }) => (islongclick === 1 ? 'visible' : 'hidden')};

  #itemChat:hover & {
    visibility: visible;
    width: 1.6rem;
  }
`;

interface IClientNameEllipsis {
  isOnlyClientName: boolean;
  isAgentOrDepartmentName: boolean;
}

export const ClientNameEllipsis = styled(Typography, {
  shouldForwardProp: shouldForwardPropHelper,
})<IClientNameEllipsis>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ isOnlyClientName, isAgentOrDepartmentName }) =>
    isOnlyClientName ? '165px' : isAgentOrDepartmentName ? '45%' : '86px'};
`;

export const LastMessage = styled(Typography)(
  () => `
  > i {
    display: inline-block;
    margin-right: 0.4rem; 
  }
`
);
