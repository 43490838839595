import { Stack, styled } from '@mui/material';
import { TextField as YDSTextField } from '@engyalo/design-system';

export const Container = styled('div')`
  max-width: 112rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 20px;
  gap: 24px;
`;

export const ConversationContainer = styled('div')`
  flex-grow: 1;
  padding-bottom: 2rem;
  max-width: 90rem;
`;

export const InputArea = styled(Stack)`
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 1rem;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const TextField = styled(YDSTextField)`
  flex-shrink: 0;

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0;
    font-size: 1rem;
  }
`;
