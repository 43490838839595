/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
/* eslint jsx-a11y/aria-role: "off" */
// FIX: Remove aria-role="presentation" from the element, or use valid role values.

import React, { useContext, useEffect, useMemo, useState } from 'react';
import ChatsHistory from 'components/ContextDrawer/ChatsHistory';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IconButton, Tooltip } from '@engyalo/design-system';
import { KebabOptionsServiceManager } from 'components/KebabOptions/KebabOptionsServiceManager';
import { Container, Header, TitleContainer } from './style';
import { DrawerMessageType, IDrawerMessages } from './types.d';
import { IRoom } from '../../types/room';
import ChatsContext from '../../contexts/chats/context';
import InputSearch from '../InputSearch';
import SnackbarCustom from '../Snackbar';
import { getRoomInfo } from '../../services/getRoomInfo';
import useSegment from '../../services/Analytics';

const DrawerMessagesServiceManager: React.FC<IDrawerMessages> = (props: IDrawerMessages) => {
  const { t } = useTranslation();
  const { roomId, type, onClose } = props;
  const [room, setRoom] = useState<IRoom>({} as IRoom);
  const [loading, setLoading] = useState(true);
  const { serviceSelected } = useContext(ChatsContext);
  const [error, setError] = useState(false);
  const [anchorElKebab, setAnchorElKebab] = useState<HTMLDivElement | null>(null);
  const [openKebab, setOpenKebab] = useState(false);
  const [inputText, setInputText] = useState('');
  const { sendEvent, EVENT_NAMES } = useSegment();
  const chatId = roomId || serviceSelected._id;

  const phone = useMemo(() => room?.v?.token.split(':').pop()!, [room]);

  const handleKebabOptionsChats = () => {
    setOpenKebab((state) => !state);
  };

  const handleOpenKebab = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElKebab(event.currentTarget);
  };

  const loadRoom = async () => {
    if (roomId || serviceSelected?._id) {
      const { data } = await getRoomInfo(chatId);
      setRoom(data.room || {});
    }
  };

  const loadValues = async () => {
    await loadRoom();
    setLoading(false);
  };

  const handleSnackbar = () => {
    setError((old: boolean) => !old);
  };

  const handleDrawerClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    loadValues();
  }, [roomId]);

  return (
    <>
      <Container>
        {!loading && (
          <>
            <Header>
              {type === DrawerMessageType.HISTORY ? (
                <>
                  <TitleContainer>
                    <IconButton
                      onClick={handleDrawerClose}
                      role="headerIconCloseHistory"
                      variant="text"
                      color="neutral2"
                    >
                      <i className="fa-regular fa-arrow-left fa-xs" />
                    </IconButton>
                    <Typography variant="subtitle2" role="headerTitleHistory">
                      {room?.livechatData?.ticket ? `Ticket ${room?.livechatData?.ticket}` : t('serviceHeldInBot')}
                    </Typography>

                    {room?.livechatData?.ticket && (
                      <Tooltip placement="bottom" title={`${t('copyTicket')}`}>
                        <i
                          role="presentation"
                          data-testid="copyTicket"
                          className="fa-light fa-copy"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '8px',
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(room?.livechatData?.ticket);
                            sendEvent(EVENT_NAMES.USER_COPY_A_TICKET_ID);
                          }}
                        />
                      </Tooltip>
                    )}
                  </TitleContainer>
                  <IconButton
                    role="iconOpenKekabMenu"
                    onClick={(event: any) => {
                      handleOpenKebab(event);
                      handleKebabOptionsChats();
                    }}
                    size="small"
                    variant="text"
                    color="neutral2"
                  >
                    <i className="fa-regular fa-ellipsis-vertical" />
                  </IconButton>
                </>
              ) : (
                <TitleContainer>
                  <Typography variant="subtitle2" role="searchMessageTitle">
                    {t('searchMessages')}
                  </Typography>
                </TitleContainer>
              )}

              {type === DrawerMessageType.SEARCH && (
                <IconButton
                  role="headerIconClose"
                  variant="text"
                  color="neutral2"
                  size="small"
                  onClick={handleDrawerClose}
                >
                  <i className="fa-regular fa-xmark" />
                </IconButton>
              )}
            </Header>
            <InputSearch
              value={inputText}
              placeholder="search"
              onChange={(value: string) => {
                setInputText(value);
              }}
            />
            {chatId ? (
              <ChatsHistory key={chatId} chatId={chatId} filterValue={inputText} phone={phone} chatDate={room.ts} />
            ) : null}
            {error && (
              <SnackbarCustom
                open={error}
                message={t('errorSearch') || ''}
                handleClose={() => handleSnackbar()}
                type="error"
              />
            )}
          </>
        )}
      </Container>

      {anchorElKebab && (
        <KebabOptionsServiceManager
          anchorElKebab={anchorElKebab}
          open={openKebab}
          handlePopoverKebab={handleKebabOptionsChats}
          itemSelected={room}
          kebabName="chatsManager"
        />
      )}
    </>
  );
};

export default DrawerMessagesServiceManager;
